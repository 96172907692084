import { config } from './config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dataUser } from '../models/findUser';

@Injectable({
    providedIn: 'root'
})
export class UsersTttk {
    public url = config.url;

    constructor(public http: HttpClient) { }

    /**
     * Funcion que retorna todos los usuarios con licencia
     */
    getUsersLicense(token,users?:dataUser) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + 'tk-users/licensetk', {
            correo: users.correo,
            nombre: users.nombre,
            cct: users.cct,           
            fechaI: users.fechaIn,
            fechaF: users.fechaFi
        }, { headers: headers });
    }

    /**
     * Funcion que retorna todos los usuarios con licencia
     */
    getUserInfo(token,id_user) {
        //Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.post(this.url + `tk-users/licensetk/${id_user}`, {} ,{ headers: headers });
    }

}