import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Links } from 'src/app/models/otros-recursos';
import { OtrosRecursosService } from 'src/app/services/otros-recursos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-link-libros',
  templateUrl: './link-libros.component.html',
  styleUrls: ['./link-libros.component.css']
})
export class LinkLibrosComponent implements OnInit {

  token: string = localStorage.getItem('token');
  public links;
  public section = 0;
  public linkact = new Links();
  public id;

  constructor(private otrosrecursosService: OtrosRecursosService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.otrosrecursosService.links(this.token).subscribe((response: any) => {
      this.section=0;
      this.links = response.message;
    }, error => {
      console.log(error.error.message);
    });
  }

  updateLink(id){
    this.id = id;
    this.otrosrecursosService.consultarLink(this.token, id).subscribe((response: any) => {
      this.linkact = response.link;
      this.section=1;
    }, error => {
      console.log(error.error.message);
    });
  }

  actualizarLink(){
    console.log(this.linkact);
    this.otrosrecursosService.actualizarLink(this.token, this.linkact, this.id).subscribe((response: any) => {
      Swal.fire({
        type: 'success',
        title: 'Link del libro actualizado',
        showConfirmButton: false,
        timer: 1500
      });
      this.ngOnInit();
    }, error => {
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 1500
      });
    });
  }

}
