import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  public url = config.url;

  constructor(public http: HttpClient) { }

  /** 
   * Login Service
   */
  login(user): Observable<any> {
    // Cabezeras
    let headers = new HttpHeaders().set('Content-Type','application/json');

    // Parámetros
    let params = user;

    // Petición
    return this.http.post(this.url + 'admin/adminlogin', params, {headers: headers});
  }

  /** 
   * Auth Service
   */
  isLogged() {
    let token = localStorage.getItem('token');

    if( token && token != 'undefined' ) {
      return true;
    } else {
      return false;
    }
  }

}
