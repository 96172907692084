import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { findUser } from '../../services/findUser.service';
import { ActivatedRoute } from '@angular/router';
import { dataUser } from '../../models/findUser';
import { Grupo } from '../../models/group';
import { groupBy } from 'rxjs/operators';
import { Student } from 'src/app/models/student';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuarios-detalle',
  templateUrl: './usuarios-detalle.component.html',
  styleUrls: ['./usuarios-detalle.component.css']
})
export class UsuariosDetalleComponent implements OnInit {

  public token = localStorage.getItem('token');
  public idUser: string;
  public info: any[] = [];
  public classrooms: any[] = [];
  public last_login: any[] = [];
  public lista_alumnos: any[] = [];
  public total_students: any[] = [];
  public license_activation: any[] = [];
  dataU = new dataUser();
  grupoU = new Grupo();
  studentU = new Student();

  constructor(private findService: findUser, private route: ActivatedRoute, private router: Router) {
    this.idUser = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    //leer el id del link
    this.findService.getPersonalUser(this.token, this.idUser).subscribe(
      response => {
        this.info = response[0];
        this.classrooms = response['classrooms'];
        this.last_login = response['last_login'];
        this.lista_alumnos = response['lista_alumnos'];
        this.total_students = response['total_students'];
        this.license_activation = response['license_activation'];
        console.log();
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

  updatePassword() {
    this.findService.setPassword(this.token, this.dataU.password, this.info['id']).subscribe(
      response => {
        Swal.fire({
          type: 'success',
          title: 'Exito',
          text: '¡' + response['message'] + '!'
        });
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al actualizar la contraseña'
        });
      }
    );
  }

  eliminarGrupo(id: string) {
    this.findService.deleteGroup(this.token, id).subscribe(
      response => {
        Swal.fire({
          type: 'success',
          title: 'Exito',
          text: '¡' + response['message'] + '!'
        });
        this.ngOnInit();
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: error.error.message + '.'
        });
      }
    );
  }

  crearGrupo() {
    if (this.grupoU.grado == "" || this.grupoU.grupo == "" || this.grupoU.color == "" || this.grupoU.letra == "") {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: '¡Complete los campos!'
      });
    } else {
      this.findService.createGroup(this.token, this.grupoU, this.info['id']).subscribe(
        response => {
          Swal.fire({
            type: 'success',
            title: 'Exito',
            text: '¡' + response['message'] + '!'
          });
          this.ngOnInit();
        }, error => {
          console.log(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: error.error.message + '.'
          });
        }
      );
    }
    this.grupoU.grado = "";
    this.grupoU.grupo = "";
    this.grupoU.color = "";
    this.grupoU.letra = "";
  }

  crearAlumno() {
    if (this.studentU.username == "" || this.studentU.fullname == "" || this.studentU.gradoS == "" || this.studentU.grupoS == "") {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: '¡Complete los campos!'
      });
    } else {
      this.findService.createStudent(this.token, this.studentU, this.info['id'], this.info['cct_id']).subscribe(
        response => {
          Swal.fire({
            type: 'success',
            title: 'Exito',
            text: '¡' + response['message'] + '!'
          });
          this.ngOnInit();
        }, error => {
          console.log(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: error.error.message + '.'
          });
        }
      );
    }
    this.studentU.username = "";
    this.studentU.fullname = "";
    this.studentU.password = "";
    this.studentU.gradoS = "";
    this.studentU.grupoS = "";
  }

}
