import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-nuevo-juego',
  templateUrl: './nuevo-juego.component.html'
})
export class NuevoJuegoComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }

}
