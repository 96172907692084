import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
 
export class CapacitacionService{
    
  public url = config.url;
  constructor(public http: HttpClient) { }

  getWebinarList(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/webinars', { headers: headers });
  }

  getWebinar(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/webinar/'+id, { headers: headers });
  }

  getTags(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/tags', { headers: headers });
  }
  
  getTemas(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/temas', { headers: headers });
  }

  getAutores(): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/autor', { headers: headers });
  }

  postTags(data:any, token:any): Observable<any>{ //error, no autorizado 401
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/tags', data, { headers: headers });
  }

  postTemas(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/temas', data, { headers: headers });
  }

  postWebinar(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/webinar', data, { headers: headers });
  }

  postStatusComment(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/edit-comentarios', data, { headers: headers });
  }

  putEditWebinar(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.put(this.url+'capacitacion/webinar', data, { headers: headers });
  }

  postEditIMG(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/webinar-editFoto', data, { headers: headers });
  }

  postEnviarVideo(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/subir-webinar', data, { headers: headers });
  }

  getMoocsList(token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/moocs', { headers: headers });
  }

  crearMooc(token:any, data): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/mooc-crear', data, { headers: headers });
  }
 
  crearModulo(token:any, data): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/mooc-crear/modulo', data, { headers: headers });
  }
  
  crearMaterial(token:any, data): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/mooc-crear/materiales', data, { headers: headers });
  }

  getMooc(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/mooc/'+id, { headers: headers });
  }

  postEditIMGMooc(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/mooc-editFoto', data, { headers: headers });
  }

  putEditMooc(data:any, token:any, id, peticion): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.put(this.url+'capacitacion/mooc/'+id+'/'+peticion, data, { headers: headers });
  }

  putEditMaterial(data:any, token:any, id): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.put(this.url+'capacitacion/mooc-crear/materiales/'+id, data, { headers: headers });
  }

  getEvaluacion(id:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'capacitacion/mooc-evaluar/'+id, { headers: headers });
  }

  postEvaluacion(data:any, token:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'capacitacion/mooc-evaluar', data, { headers: headers });
  }

}