import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/games.service';
import { Game } from '../../models/game';

import { Router } from '@angular/router';


@Component({
  selector: 'app-juegos',
  templateUrl: './juegos.component.html'
})


export class JuegosComponent implements OnInit {
  refresh: string;
  games: Game[] = [];
  token: string = localStorage.getItem('token');
  constructor(private gameService: GameService, private router: Router) {
    this.gameService.verJuegos(this.token).subscribe((response: any) => {
      this.games = response.games;      
    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit() {
    this.refresh = localStorage.getItem('refresh');
    if(this.refresh === "false"){
      location.reload();
      localStorage.removeItem('refresh');      
    }

  }

  agregarJuego() {
    this.router.navigate(['juego/nuevo']);
  }

}
