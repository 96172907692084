import { TagsService } from './../../services/tags.service';
import { GameService } from './../../services/games.service';
import { GameAbility } from './../../models/game';
import Swal from 'sweetalert2';
import { Component, OnInit, Input } from '@angular/core';
import {NgForm} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-abilities',
  templateUrl: './abilities.component.html',
  styleUrls: ['./abilities.component.css']
})
export class AbilitiesComponent implements OnInit {
  @Input()gameId: number;
  @Input() update: number;
  
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  public gameAbility = new GameAbility();
  private up = '0';
  private token = localStorage.getItem('token');
  
  constructor( private gameService: GameService, private activatedRoute: ActivatedRoute, private router: Router,private tagsService: TagsService) {
    this.activatedRoute.params.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
          this.gameAbility.game_id = response.game.id;
          this.gameId = response.game.id;
          if (response.habilidades === null ) {
            console.log(response);
            this.gameAbility.modelos = {
              autoconocimiento: {
                conciencia: 0,
                autoestima: 0,
                aprecio: 0,
                bienestar: 0
              },
              autorregulacion: {
                meta: 0,
                expresion: 0,
                regulacion: 0,
                generacion: 0,
                perseverancia: 0
              },
              autonomia: {
                iniciativa: 0,
                soluciones: 0,
                eficacia: 0
              },
              empatia: {
                bienestar: 0,
                perspectiva: 0,
                prejuicios: 0,
                sensibilidad: 0,
                cuidado: 0
              },
              colaboracion: {
                comunidad: 0,
                responsabilidad: 0,
                inclusion: 0,
                resolucion: 0,
                interdependencia: 0
              },
              ramas: {
                colaboracion: 0,
                empatia: 0,
                autonomia: 0,
                autorregulacion: 0,
                autoconocimiento: 0
              },
              modelo: 0
            };
            this.gameAbility.habilidades = {
              aprendizaje: {
                pensamiento: 0,
                comunicacion: 0,
                creatividad: 0,
                colaboracion: 0
              },
              imt: {
                teclado: {
                  nombre: '',
                  porcentaje: 0
                },
                mouse: {
                  nombre: '',
                  porcentaje: 0
                },
                tableta: {
                  nombre: '',
                  porcentaje: 0
                }
              },
              fms: {
                atencion: 0,
                memoria: 0,
                lenguaje: 0,
                visoespacial: 0,
                sensoriomotoras: 0,
                ejecutivas: 0
              },
              ed: {
                visual: 0,
                trabajo: 0,
                informacion: 0,
                verbal: 0,
                auditiva: 0
              },
              tags: ''
            };
          } else {
            console.log(response.habilidades);
            console.log(response.habilidades.me);
            console.log(response.habilidades.me.autonomia);
            const modelodb = JSON.parse(response.habilidades.me);
            console.log(modelodb);
            console.log(modelodb.autonomia.eficacia);
            const aprendizaje = JSON.parse(response.habilidades.aprendizaje);
            const imt = JSON.parse(response.habilidades.imt);
            const fms = JSON.parse(response.habilidades.fms);
            const ed = JSON.parse(response.habilidades.ed);
            this.gameAbility.modelos = {
              autoconocimiento: {
                conciencia: modelodb.autoconocimiento.conciencia ,
                autoestima: modelodb.autoconocimiento.autoestima,
                aprecio: modelodb.autoconocimiento.aprecio ,
                bienestar: modelodb.autoconocimiento.bienestar
              },
              autorregulacion: {
                meta: modelodb.autorregulacion.meta,
                expresion: modelodb.autorregulacion.expresion ,
                regulacion: modelodb.autorregulacion.regulacion ,
                generacion: modelodb.autorregulacion.generacion ,
                perseverancia: modelodb.autorregulacion.perseverancia 
              },
              autonomia: {
                iniciativa: modelodb.autonomia.iniciativa ,
                soluciones: modelodb.autonomia.soluciones ,
                eficacia: modelodb.autonomia.eficacia 
              },
              empatia: {
                bienestar: modelodb.empatia.bienestar ,
                perspectiva: modelodb.empatia.perspectiva ,
                prejuicios: modelodb.empatia.prejuicios ,
                sensibilidad: modelodb.empatia.sensibilidad ,
                cuidado: modelodb.empatia.cuidado 
              },
              colaboracion: {
                comunidad: modelodb.colaboracion.comunidad ,
                responsabilidad: modelodb.colaboracion.responsabilidad ,
                inclusion: modelodb.colaboracion.inclusion ,
                resolucion: modelodb.colaboracion.resolucion ,
                interdependencia: modelodb.colaboracion.interdependencia 
              },
              ramas: {
                colaboracion: modelodb.ramas.colaboracion ,
                empatia: modelodb.ramas.empatia ,
                autonomia: modelodb.ramas.autonomia ,
                autorregulacion: modelodb.ramas.autorregulacion,
                autoconocimiento: modelodb.ramas.autoconocimiento 
              },
              modelo: modelodb.modelo
            };
            this.gameAbility.habilidades = {
              aprendizaje: {
                pensamiento: aprendizaje.pensamiento ,
                comunicacion: aprendizaje.comunicacion ,
                creatividad: aprendizaje.creatividad ,
                colaboracion: aprendizaje.colaboracion 
            },
            imt: {
                teclado: {
                    nombre: imt.teclado.nombre,
                    porcentaje: imt.teclado.porcentaje = imt.teclado.porcentaje 
                },
                mouse: {
                    nombre: imt.mouse.nombre,
                    porcentaje: imt.mouse.porcentaje = imt.mouse.porcentaje 
                },
                tableta: {
                    nombre: imt.tableta.nombre,
                    porcentaje: imt.tableta.porcentaje = imt.tableta.porcentaje 
                }
            },
            fms: {
                atencion: fms.atencion,
                memoria: fms.memoria,
                lenguaje: fms.lenguaje,
                visoespacial: fms.visoespacial,
                sensoriomotoras: fms.sensoriomotoras,
                ejecutivas: fms.ejecutivas
            },
            ed: {
                visual: ed.visual,
                trabajo: ed.trabajo,
                informacion: ed.informacion,
                verbal: ed.verbal,
                auditiva: ed.auditiva
            },
            tags: response.habilidades.tags
            };
            this.up = '1';
            const regex = /\s*,\s*/;
            this.tags = this.gameAbility.habilidades.tags.split(regex);
            this.tags = this.tags.filter((element) => {
              return element !== '';
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });
    this.tagsService.getTags().subscribe(
      response => {
        this.tagsDB = Object.values(response);
        let auxTag = [];
        for (const tag of this.tagsDB) {
          auxTag.push(tag.nombre);
        }
        this.tagsDB = auxTag;
      }, error => {
        console.log(error.message);
      }
    );
   }

  ngOnInit() {
  }
  addCompetencia( form: NgForm ) {
    if (form.valid) {
      console.log(form.value);
      let gameDataAbility = new GameAbility();
      if (this.gameId !== 0 && this.gameId !== undefined) {
        gameDataAbility.game_id = this.gameId;
        if (this.update === 1 && this.up === '1') {
          gameDataAbility.peticion = '1';
        }
        const modelos = {
          autoconocimiento: {
            conciencia: form.value.conciencia === true ? 1 : 0 || form.value.conciencia === 1 ? 1 : 0,
            autoestima: form.value.autoestima === true ? 1 : 0 || form.value.autoestima === 1 ? 1 : 0,
            aprecio: form.value.aprecio === true ? 1 : 0 || form.value.aprecio === 1 ? 1 : 0  ,
            bienestar: form.value.bienestar === true ? 1 : 0 || form.value.bienestar === 1 ? 1 : 0 
          },
          autorregulacion: {
            meta: form.value.meta === true ? 1 : 0 || form.value.meta === 1 ? 1 : 0,
            expresion: form.value.expresion === true ? 1 : 0 || form.value.expresion === 1 ? 1 : 0,
            regulacion: form.value.regulacion === true ? 1 : 0 || form.value.regulacion === 1 ? 1 : 0 ,
            generacion: form.value.generacion === true ? 1 : 0 || form.value.generacion === 1 ? 1 : 0,
            perseverancia: form.value.perseverancia === true ? 1 : 0 || form.value.perseverancia === 1 ? 1 : 0
          },
          autonomia: {
            iniciativa: form.value.iniciativa === true ? 1 : 0 || form.value.iniciativa === 1 ? 1 : 0  ,
            soluciones: form.value.soluciones === true ? 1 : 0 || form.value.soluciones === 1 ? 1 : 0,
            eficacia: form.value.eficacia === true ? 1 : 0 || form.value.eficacia === 1 ? 1 : 0
          },
          empatia: {
            bienestar: form.value.bienestart === true ? 1 : 0 || form.value.bienestart === 1 ? 1 : 0,
            perspectiva: form.value.perspectiva === true ? 1 : 0 || form.value.perspectiva === 1 ? 1 : 0,
            prejuicios: form.value.prejuicios === true ? 1 : 0 || form.value.prejuicios === 1 ? 1 : 0,
            sensibilidad: form.value.sensibilidad === true ? 1 : 0 || form.value.sensibilidad === 1 ? 1 : 0,
            cuidado: form.value.cuidado === true ? 1 : 0 || form.value.cuidado === 1 ? 1 : 0
          },
          colaboracion: {
            comunidad: form.value.comunidad === true ? 1 : 0 || form.value.comunidad === 1 ? 1 : 0,
            responsabilidad: form.value.responsabilidad === true ? 1 : 0 || form.value.responsabilidad === 1 ? 1 : 0,
            inclusion: form.value.inclusion === true ? 1 : 0 || form.value.inclusion === 1 ? 1 : 0,
            resolucion: form.value.resolucion === true ? 1 : 0 || form.value.resolucion === 1 ? 1 : 0,
            interdependencia: form.value.interdependencia === true ? 1 : 0 || form.value.interdependencia === 1 ? 1 : 0
          },
          ramas : {
            colaboracion: form.value.colaboracion === true ? 1 : 0 || form.value.colaboracion === 1 ? 1 : 0,
            empatia: form.value.empatia === true ? 1 : 0 || form.value.empatia === 1 ? 1 : 0,
            autonomia: form.value.autonomia === true ? 1 : 0 || form.value.autonomia === 1 ? 1 : 0,
            autorregulacion: form.value.autorregulacion === true ? 1 : 0 || form.value.autorregulacion === 1 ? 1 : 0,
            autoconocimiento: form.value.autoconocimiento === true ? 1 : 0 || form.value.autoconocimiento === 1 ? 1 : 0
          },
          modelo: 0
        };
        const habilidades = {
          aprendizaje: {
            pensamiento: form.value.pensamiento === true ? 1 : 0 || form.value.pensamiento === 1 ? 1 : 0,
            comunicacion: form.value.comunicacion === true ? 1 : 0 || form.value.comunicacion === 1 ? 1 : 0,
            creatividad: form.value.creatividad === true ? 1 : 0 || form.value.creatividad === 1 ? 1 : 0,
            colaboracion: form.value.colaboracion === true ? 1 : 0 || form.value.colaboracion === 1 ? 1 : 0
          },
          imt: {
              teclado: {
                  nombre: form.value.tecladoNombre,
              porcentaje: form.value.tecladoPorcentaje === true ? 100 : 0 || form.value.tecladoPorcentaje === 100 ? 100 : 0 
              },
              mouse: {
                  nombre: form.value.mouseNombre,
                porcentaje: form.value.mousePorcentaje === true ? 100 : 0 || form.value.mousePorcentaje === 100 ? 100 : 0 
              },
              tableta: {
                  nombre: form.value.tabletaNombre,
                porcentaje: form.value.tabletaPorcentaje === true ? 100 : 0 || form.value.tabletaPorcentaje === 100 ? 100 : 0 
              }
          },
          fms: {
              atencion: form.value.atencion,
              memoria: form.value.memoria,
              lenguaje: form.value.lenguaje,
              visoespacial: form.value.visoespacial,
            sensoriomotoras: form.value.sensoriomotoras,
              ejecutivas: form.value.ejecutivas
          },
          ed: {
              visual: form.value.visual,
              trabajo: form.value.trabajo,
              informacion: form.value.informacion,
              verbal: form.value.verbal,
              auditiva: form.value.auditiva
          },
          tags: form.value.tags
        };
        gameDataAbility.habilidades = habilidades;
        gameDataAbility.modelos = modelos;
        if(gameDataAbility.modelos.autonomia.eficacia === 1 || gameDataAbility.modelos.autonomia.iniciativa === 1 ||
          gameDataAbility.modelos.autonomia.soluciones === 1 && gameDataAbility.modelos.ramas.autonomia === 0) {
          gameDataAbility.modelos.ramas.autonomia=1;
        }
        if (gameDataAbility.modelos.autoconocimiento.conciencia === 1 || gameDataAbility.modelos.autoconocimiento.autoestima === 1 || 
          gameDataAbility.modelos.autoconocimiento.aprecio === 1 || gameDataAbility.modelos.autoconocimiento.bienestar === 1 
          && gameDataAbility.modelos.ramas.autoconocimiento === 0) {
          gameDataAbility.modelos.ramas.autoconocimiento = 1;
        }
        if(gameDataAbility.modelos.autorregulacion.meta === 1 || gameDataAbility.modelos.autorregulacion.expresion === 1 ||
           gameDataAbility.modelos.autorregulacion.regulacion === 1 || gameDataAbility.modelos.autorregulacion.generacion === 1 || 
          gameDataAbility.modelos.autorregulacion.perseverancia === 1 && gameDataAbility.modelos.ramas.autorregulacion === 0) {
           gameDataAbility.modelos.ramas.autorregulacion =  1;
        }
        if(gameDataAbility.modelos.empatia.bienestar === 1 || gameDataAbility.modelos.empatia.perspectiva === 1  || 
          gameDataAbility.modelos.empatia.prejuicios === 1 || gameDataAbility.modelos.empatia.sensibilidad === 1  || 
          gameDataAbility.modelos.empatia.cuidado === 1 && gameDataAbility.modelos.ramas.empatia === 0 ) {
          gameDataAbility.modelos.ramas.empatia =  1;
        }
        if(gameDataAbility.modelos.colaboracion.comunidad === 1 || gameDataAbility.modelos.colaboracion.responsabilidad === 1 ||
           gameDataAbility.modelos.colaboracion.inclusion === 1 || gameDataAbility.modelos.colaboracion.resolucion === 1 ||
          gameDataAbility.modelos.colaboracion.interdependencia === 1 && gameDataAbility.modelos.ramas.colaboracion === 0) {
           gameDataAbility.modelos.ramas.colaboracion =  1;
        }
        if( gameDataAbility.modelos.ramas.autonomia === 1 || gameDataAbility.modelos.ramas.autoconocimiento === 1||
            gameDataAbility.modelos.ramas.autorregulacion === 1 || gameDataAbility.modelos.ramas.empatia === 1 || 
            gameDataAbility.modelos.ramas.colaboracion === 1) {
            gameDataAbility.modelos.modelo = 1;
        }
        console.log(gameDataAbility);
        this.gameService.addAbility(this.token, gameDataAbility).subscribe(
          response => {
            Swal.fire({
              type: 'success',
              title: 'Se creo la habilidad',
              showConfirmButton: false,
              timer: 1500
            });
          }, error => {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear la habilidad:' + error.error.message
            });
          }
        );
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Escoge un juego para crear la ficha'
        });
      }
    } else {
      console.log(form);
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
    }
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd = this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        const caracter = this.gameAbility.habilidades.tags[this.gameAbility.habilidades.tags.length - 1];
        console.log(caracter);
        this.gameAbility.habilidades.tags = this.gameAbility.habilidades.tags + `, ${arrTags[arrTags.length - 2].trim()}`;
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.gameAbility.habilidades.tags = '';
      if (this.tags.length > 0) {
        this.gameAbility.habilidades.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.gameAbility.habilidades.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.gameAbility.habilidades.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.gameAbility.habilidades.tags = '';
      if (this.tagsNew.length > 0) {
        this.gameAbility.habilidades.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.gameAbility.habilidades.tags = `${this.tags.join(',')}`;
        } else {
          this.gameAbility.habilidades.tags = '';
        }
      }
    }
  }
}