import { Component, OnInit } from '@angular/core';
import { Foro, Moocs, Planeaciones, PlaneacionesSes, Webinars } from 'src/app/models/secciones';
import { SeccionesService } from 'src/app/services/secciones.service';
import Swal from 'sweetalert2';


declare var $: any;

@Component({
  selector: 'app-secciones-lt',
  templateUrl: './secciones-lt.component.html',
  styleUrls: ['./secciones-lt.component.css']
})
export class SeccionesLtComponent implements OnInit {
  token: string = localStorage.getItem('token');
  public mostrar=0;
  public section= 0;
  public section_ed = 0;
  public status_secc = 1;
  public name_secc='';

  public planeaciones = [];
  public planeaciones_ses = [];
  public foro = [];
  public moocs = [];
  public webinars = [];

  public planeacion_detalle = new Planeaciones();
  public plan_ses_detalle = new PlaneacionesSes();
  public foro_detalle = new Foro();
  public mooc_detalle = new Moocs();
  public webinar_detalle = new Webinars();

  constructor(private seccionesService: SeccionesService) {
    this.seccionesService.getPlaneaciones(this.token).subscribe((response: any) => {
      this.planeaciones = response.planeaciones;
    });
    this.seccionesService.getPlaneacionesSesion(this.token).subscribe((response: any) => {
      this.planeaciones_ses = response.planeaciones_ses;
    });
    this.seccionesService.getForo(this.token).subscribe((response: any) => {
      this.foro = response.foros;
    });
    this.seccionesService.getMoocs(this.token).subscribe((response: any) => {
      this.moocs = response.moocs;
    });
    this.seccionesService.getWebinars(this.token).subscribe((response: any) => {
      this.webinars = response.webinars;
    });
   }

  ngOnInit() {
  }

  regresar(){
    this.mostrar = 0;
  }

  agregarNueva(){ 
    this.name_secc='';
    this.section= 0;
    $(".modal-section").modal("show");
  }
  agregarSeccion(){
    if(this.section==1){
      let np = new Planeaciones();
      np.tipo_planeacion = this.name_secc;
      this.seccionesService.nuevaPlaneaciones(this.token, np).subscribe((response: any) => {
        this.planeaciones.push(np);
          Swal.fire({
            type: 'success',
            title: 'Nueva sección creada. ',
            showConfirmButton: false,
            timer: 2000
          });
      });
    }else if(this.section==2){
      let np = new PlaneacionesSes();
      np.tipo_planeaciones_ses = this.name_secc;
      this.seccionesService.nuevaPlaneacionesSesion(this.token, np).subscribe((response: any) => {
        this.planeaciones_ses.push(np);
          Swal.fire({
            type: 'success',
            title: 'Nueva sección creada. ',
            showConfirmButton: false,
            timer: 2000
          });
      });
    }else if(this.section==3){
      let np = new Foro();
      np.tipo_foro = this.name_secc;
      this.seccionesService.nuevaForo(this.token, np).subscribe((response: any) => {
        this.foro.push(np);
          Swal.fire({
            type: 'success',
            title: 'Nueva sección creada. ',
            showConfirmButton: false,
            timer: 2000
          });
      });
    }else if(this.section==4){
      let np = new Moocs();
      np.nombre = this.name_secc;
      this.seccionesService.nuevaMoocs(this.token, np).subscribe((response: any) => {
        this.moocs.push(np);
          Swal.fire({
            type: 'success',
            title: 'Nueva sección creada. ',
            showConfirmButton: false,
            timer: 2000
          });
      });
    }else if(this.section==5){
      let np = new Webinars();
      np.tipo_webinar = this.name_secc;
      this.seccionesService.nuevaWebinars(this.token, np).subscribe((response: any) => {
        this.webinars.push(np);
          Swal.fire({
            type: 'success',
            title: 'Nueva sección creada. ',
            showConfirmButton: false,
            timer: 2000
          });
      });
    }
  }

  editarPlaneacion(id){
    this.section_ed = 1;
    this.seccionesService.getPlaneacion(this.token, id).subscribe((response: any) => {
      this.planeacion_detalle = response.planeacion;
      this.status_secc = this.planeacion_detalle.status;
      $(".modal-editar").modal("show");
    });
  }

  editarPlaneacionSesion(id){
    this.section_ed = 2;
    this.seccionesService.getPlaneacionSesion(this.token, id).subscribe((response: any) => {
      this.plan_ses_detalle = response.planeacion_ses;
      this.status_secc = this.plan_ses_detalle.status;
      $(".modal-editar").modal("show");
    });
  }

  editarForo(id){
    this.section_ed = 3;
    this.seccionesService.getForoD(this.token, id).subscribe((response: any) => {
      this.foro_detalle = response.foro;
      this.status_secc = this.foro_detalle.status;
      $(".modal-editar").modal("show");
    });
  }

  editarMoocs(id){
    this.section_ed = 4;
    this.seccionesService.getMooc(this.token, id).subscribe((response: any) => {
      this.mooc_detalle = response.mooc;
      this.status_secc = this.mooc_detalle.status;
      $(".modal-editar").modal("show");
    });
  }

  editarWebinars(id){
    this.section_ed = 5;
    this.seccionesService.getWebinar(this.token, id).subscribe((response: any) => {
      this.webinar_detalle = response.webinar;
      this.status_secc = this.webinar_detalle.status;
      $(".modal-editar").modal("show");
    });
  }

  guardarCambios(){
    let checar = 0;
    if(this.section_ed==1){
      this.planeacion_detalle.status=this.status_secc;
      this.seccionesService.editarPlaneaciones(this.token, this.planeacion_detalle, this.planeacion_detalle.id).subscribe((response: any) => {
        checar = 1;
      });
    }else if(this.section_ed==2){
      this.plan_ses_detalle.status=this.status_secc;
      this.seccionesService.editarPlaneacionesSesion(this.token, this.plan_ses_detalle, this.plan_ses_detalle.id).subscribe((response: any) => {
        checar = 1;
      });
    }else if(this.section_ed==3){
      this.foro_detalle.status=this.status_secc;
      this.seccionesService.editarForo(this.token, this.foro_detalle, this.foro_detalle.id).subscribe((response: any) => {
        checar = 1;
      });
    }else if(this.section_ed==4){
      this.mooc_detalle.status=this.status_secc;
      this.seccionesService.editarMoocs(this.token, this.mooc_detalle, this.mooc_detalle.id).subscribe((response: any) => {
         checar = 1;
      });
    }else if(this.section_ed==5){
      this.webinar_detalle.status=this.status_secc;
      this.seccionesService.editarWebinars(this.token, this.webinar_detalle, this.webinar_detalle.id).subscribe((response: any) => {
         checar = 1;
      });
    }

    Swal.fire({
      type: 'success',
      title: 'Seccion actualizada ',
      showConfirmButton: false,
      timer: 2000
    });
    this.status_secc=1;
  }

}
