import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/games.service';
import { TagsService } from '../../services/tags.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { GameRecurso } from '../../models/game'; 
@Component({
  selector: 'app-game-recursos',
  templateUrl: './game-recursos.component.html'
})
export class GameRecursosComponent implements OnInit {
  @Input() gameId: number;
  @Input() update: number;
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  private up = '0';
  public gameRec = new GameRecurso();
  public Editor = ClassicEditor;
  private token = localStorage.getItem('token');
  constructor(
    private gameService: GameService,
    private activedRoute: ActivatedRoute,
    private tagsService: TagsService) {
    this.activedRoute.params.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this.gameService.verDetalle(this.token, params.slug).subscribe(
          response => {
            this.gameRec.game_id = response.game.id;
            if (response.otros !== null) {
              this.gameRec.khan_academy = response.otros.khan_academy;
              this.gameRec.sitios = response.otros.sitios;
              this.gameRec.otras_actividades = response.otros.otras_actividades;
              this.gameRec.videos = response.otros.videos;
              this.gameRec.tags = response.otros.tags;
              const regex = /\s*,\s*/;
              this.tags = this.gameRec.tags.split(regex);
              this.tags = this.tags.filter((element) => {
                return element !== '';
              });
              this.up = '1';
            }
          }, error => {
            console.log(error);
          }
        );
      }
    });
    this.tagsService.getTags().subscribe(
      response => {
        this.tagsDB = Object.values(response);
        let auxTag = [];
        for (const tag of this.tagsDB) {
          auxTag.push(tag.nombre);
        }
        this.tagsDB = auxTag;
      }, error => {
        console.log(error.message);
      }
    );
  }

  ngOnInit() {
  }

  addRecursos(form: NgForm) {
    if (form.valid) {
      let gameData = new GameRecurso();
      if (this.gameId !== 0 && this.gameId !== undefined) {
        if (this.update === 1 && this.up === '1') {
          gameData.peticion = '1';
        }
        gameData.game_id = this.gameId;
        gameData.khan_academy = form.value.khan_academy;
        gameData.sitios = form.value.sitios;
        gameData.videos = form.value.videos;
        gameData.otras_actividades = form.value.otras_actividades;
        gameData.tags = form.value.tags;
        console.log(gameData);
        this.gameService.addRecursos(this.token, gameData).subscribe(
          response => {
            Swal.fire({
              type: 'success',
              title: 'Se creo el recurso',
              showConfirmButton: false,
              timer: 1500
            });
            localStorage.removeItem('my64');
            sessionStorage.removeItem('mypdf64');
          }, error => {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops',
              text: 'Ocurrio un error al crear el recurso: ' + error.message
            });
          }
        );
      }
    }
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd =  this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        const caracter = this.gameRec.tags[this.gameRec.tags.length - 1];
        console.log(caracter);
        this.gameRec.tags = this.gameRec.tags + `, ${arrTags[arrTags.length - 2].trim()}`;
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx] ) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.gameRec.tags = '';
      if (this.tags.length > 0) {
        this.gameRec.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.gameRec.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.gameRec.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.gameRec.tags = '';
      if (this.tagsNew.length > 0) {
        this.gameRec.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.gameRec.tags = `${this.tags.join(',')}`;
        } else {
          this.gameRec.tags = '';
        }
      }
    }
  }
}
