import { Component, OnInit } from '@angular/core';
import { usersAU, newStudent } from 'src/app/models/findUser';
import { findUser } from 'src/app/services/findUser.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-au-add-student',
  templateUrl: './au-add-student.component.html',
  styleUrls: ['./au-add-student.component.css']
})
export class AuAddStudentComponent implements OnInit {

  dataU = new usersAU();
  contactModel = new newStudent();
  list: any[] = [];
  datateacher: any;
  id: any;
  classrooms: any;
  tekaclassrooms: any;
  students: any;
  roles: any;
  private token: string = localStorage.getItem('token');
  constructor(private findService: findUser, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params;
      console.log(this.id);
    });

  }

  ngOnInit() {
    this.findService.getSources().subscribe(
      response => {
        this.roles = response['espacios'];
        console.log(response['roles']);
        console.log(response['espacios']);
        console.log(response['grupos']);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );

    const token = localStorage.getItem('token');
    this.findService.detailAU(token, this.id[" id"]).subscribe(
      response => {
        this.datateacher = response;
        this.classrooms = response['classrooms'];
        this.tekaclassrooms = response['tekaclassrooms'];
        this.students = response['tekastudents'];
        console.log(this.tekaclassrooms);
        console.log(this.classrooms);
        console.log(this.datateacher);
        console.log(this.students);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }
  crearStudent(form: NgForm) {
    console.log(form.value);
    
    if (form.valid) {
      this.contactModel = form.value;
      this.contactModel.cct_id = this.datateacher['0'].cct_id;
      this.contactModel.user_id = this.id[" id"];
      console.log(this.contactModel);
      this.findService.addStudentTeka(this.token, this.contactModel).subscribe(
        (response: any) => {
          Swal.fire({
            type: 'success',
            title: 'Usuario creado ',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['au-detail-students', this.id[" id"]]);
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al crear el usuario:' + error.error.message
          });
          // this.router.navigate(['lista-users']);
        });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
    }
  }
  verAlumnos(id) {
    this.router.navigate(['au-detail-students', id]);
  }
  back() {
    this.router.navigate(['au-detail-students', this.id[" id"]]);
  }
  addStudent() {
    this.router.navigate(['new-student']);
  }
}
