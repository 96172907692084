import { Component, OnInit } from '@angular/core';
import { dataUser } from '../../models/findUser';
import { findUser } from '../../services/findUser.service';
import Swal from 'sweetalert2';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  dataU = new dataUser();
  sources: any[] = [];
  list: any[] = [];
  private refresh:any;
  constructor(private findService: findUser) {

  }

  ngOnInit() {
    this.findService.getSources().subscribe(
      response => {
        this.sources = response['sources'];
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
    this.refresh = localStorage.getItem('refresh');
    if(this.refresh === "false"){
      location.reload();
      localStorage.removeItem('refresh');      
    }
  }

  onSubmit() {
    const token = localStorage.getItem('token');
    console.log(this.dataU);
    this.findService.buscarUsuarios(token, this.dataU).subscribe(
      response => {
        this.list = response['lista'];
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

}
