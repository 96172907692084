import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersTttk } from 'src/app/services/licencias-ttteka.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licencias-ttteka-detalle',
  templateUrl: './licencias-ttteka-detalle.component.html',
  styleUrls: ['./licencias-ttteka-detalle.component.css']
})
export class LicenciasTttekaDetalleComponent implements OnInit {
  public idUser: string;
  token = localStorage.getItem('token');
  info: string[] = [];
  payment: string[] = [];
  maestrosVin: string[] = [];
  maestrosPen: string[] = [];
  modulosComp:string[] = [];

  constructor(private route: ActivatedRoute, private ttkU: UsersTttk) {
    this.idUser = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.ttkU.getUserInfo(this.token, this.idUser).subscribe(
      response => {                  
        this.info = response["usuario"]["basic_info"];
        this.payment = response["usuario"]["payment"];
        this.maestrosVin = response["usuario"]["maestros_vinculados"];
        this.maestrosPen = response["usuario"]["maestros_pendientes"];
        this.modulosComp = response["usuario"]["modulos_comprados"];
      }
    );
  }

}
