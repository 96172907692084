import { ListaUser } from './../../models/lista-user';
import { ListaUsersService } from './../../services/lista-users.service';
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-users',
  templateUrl: './lista-users.component.html',
})
export class ListaUsersComponent implements OnInit {
  listausers: ListaUser[] = [];
  token: string = localStorage.getItem('token');
  constructor(private listausersService: ListaUsersService, private router: Router) {
    this.listausersService.mostrarUsuarios(this.token).subscribe((response: any) => {
      this.listausers = response;
      console.log(this.listausers);
    }, error => {
      console.log(error.error.message);
    });
  }
  ngOnInit() {
  }
  mostrarUser(id) {
    this.router.navigate(['lista-user', id]);
  }
  addUser() {
    this.router.navigate(['new']);
  }
  updateUser(id) {
    this.router.navigate(['update', id]);
  }
   // Funcion que se activa cuando se desea eliminar un  usuario 
   eliminarUser(id, idx: number) {
    this.listausersService.eliminarUser(this.token, id).subscribe(
      (response: any) => {
        console.log(response);
        if (response) {
          this.listausers[ idx ].status = 0;
          console.log(this.listausers[idx]);
          Swal.fire({
            type: 'success',
            title: 'Se desactivo el usuario',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.router.navigate(['lista-users']);

      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Este usuario ya esta inactivo',
          timer: 1500
        });
        this.router.navigate(['lista-users']);
        // en caso de que el usuario no se puede borrar porque no existe te redirecciona al inicio 
      });
  }


}

