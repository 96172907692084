import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibrosTexto } from 'src/app/models/otros-recursos';
import { OtrosRecursosService } from 'src/app/services/otros-recursos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-libro',
  templateUrl: './update-libro.component.html',
  styleUrls: ['./update-libro.component.css']
})
export class UpdateLibroComponent implements OnInit {

  public librostx = new LibrosTexto();
  public links;
  public tags: string[] = [];
  public tagsbd;
  public id;
  token: string = localStorage.getItem('token');

  constructor(private otrosrecursosService: OtrosRecursosService,  private activatedRoute: ActivatedRoute, private router: Router) {
    this.otrosrecursosService.links(this.token).subscribe((response: any) => {
      this.links = response.message;
    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });

    let re = /-/gi;

    this.otrosrecursosService.consultarLibro(this.token, this.id).subscribe((response: any) => {
      console.log(response)
      this.librostx = response.libro;
      this.tagsbd = this.librostx.tags.split(',');
      this.tagsbd.forEach(tb => {
        tb.includes('-') ? this.tags.push(tb.replace(re,' ')) : this.tags.push(tb);
      });
    }, error => {
      console.log(error.error.message);
    });
  }

  cancelar() {
    this.router.navigate(['libros']);
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if(arrTags.length>=2 && arrTags[1]!=''){
      arrTags.forEach(element => {
        if(element!=''){ 
          if (this.tags.indexOf(element.trim()) === -1) {
            this.tags.push(element.trim());
            this.librostx.tags=='' ? this.librostx.tags = element.trim() : 
            this.librostx.tags = this.librostx.tags + ',' + element.trim();
          }
        }
      });
      event.target.value = '';
    }
    else{
      if (tags[tags.length - 1] === ',') {
        const elemento = arrTags[arrTags.length - 2].trim();
        if (this.tags.indexOf(elemento) === -1) {
            if (this.tags.indexOf(elemento.trim()) === -1) {
              this.tags.push(elemento);
            }
          const caracter = this.librostx.tags[this.librostx.tags.length - 1];
          this.librostx.tags=='' ? this.librostx.tags= `${arrTags[arrTags.length - 2].trim()}` :
          this.librostx.tags = this.librostx.tags + `, ${arrTags[arrTags.length - 2].trim()} `;//-2
          event.target.value = '';
        }
      }
    }
  }

  eliminarTag(idx: number) {
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.librostx.tags = '';
      if (this.tags.length > 0) {
        this.librostx.tags = `${this.tags.join(',')}`;
      }
    }
  }

  editarLibro(){
    this.librostx.peticion = 1;

    this.otrosrecursosService.actualizarLibro(this.token, this.librostx ,this.id).subscribe((response: any) => {
      Swal.fire({
        type: 'success',
        title: 'Tema del libro actualizado',
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['libros']);
    }, error => {
      Swal.fire({
        type: 'error',
        title: error.error.message,
        showConfirmButton: false,
        timer: 1500
      });
    });
  }

  changeData(){
    if(this.librostx.link!=''){
      console.log(this.librostx.link)
      this.links.forEach(l => {
        if(l.id==this.librostx.link){
          this.librostx.libro = l.nombre;
          this.librostx.grado = l.grado;
        }
      });
    }
  }

}
