import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LibrosTexto } from 'src/app/models/otros-recursos';
import { OtrosRecursosService } from 'src/app/services/otros-recursos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-libros',
  templateUrl: './libros.component.html',
  styleUrls: ['./libros.component.css']
})
export class LibrosComponent implements OnInit {

  public libros;

  token: string = localStorage.getItem('token');

  constructor(private otrosrecursosService: OtrosRecursosService, private router: Router) {
    this.otrosrecursosService.mostrarLibros(this.token).subscribe((response: any) => {
      this.libros = response.message;
    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit() {
  }

  nuevoLibro(){
    this.router.navigate(['libro-nuevo']);
  }

  deleteLibro(id, idx: number) {
    this.otrosrecursosService.eliminarLibro(this.token, id).subscribe(
      (response: any) => {
        this.libros[idx].status = false;
        Swal.fire({
          type: 'success',
          title: 'Libro de texto eliminado',
          showConfirmButton: false,
          timer: 1500
        });

      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Este libro de texto ya esta inactivo',
          showConfirmButton: false,
          timer: 1500
        });
      });
  }
  updateLibro(id) {
    this.router.navigate(['update-libro', id]);
  }

}
