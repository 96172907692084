import { Injectable } from '@angular/core';
import { config } from './config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Planeaciones, PlaneacionesSes, Foro, Webinars, Moocs } from '../models/secciones';

@Injectable({
  providedIn: 'root'
})
export class SeccionesService {

  public url = config.url;

  constructor(public http: HttpClient) { }

  getPlaneaciones(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/planeacion', { headers: headers });
  }
  getPlaneacionesSesion(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/planeacion-ses', { headers: headers });
  }
  getForo(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/foro', { headers: headers });
  }
  getWebinars(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/webinar', { headers: headers });
  }
  getMoocs(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/moocs', { headers: headers });
  }

  getPlaneacion(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/planeacion/' + id, { headers: headers });
  }
  getPlaneacionSesion(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/planeacion-ses/' + id, { headers: headers });
  }
  getForoD(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/foro/' + id, { headers: headers });
  }
  getWebinar(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/webinar/' + id, { headers: headers });
  }
  getMooc(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'seccion/moocs/' + id, { headers: headers });
  }

  nuevaPlaneaciones(token: string , planeacion: Planeaciones) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'seccion/planeacion', planeacion, { headers: headers });
  }
  nuevaPlaneacionesSesion(token: string, planeacion_ses: PlaneacionesSes) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'seccion/planeacion-ses', planeacion_ses, { headers: headers });
  }
  nuevaForo(token: string, foro: Foro) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'seccion/foro', foro, { headers: headers });
  }
  nuevaWebinars(token: string, webinar: Webinars) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'seccion/webinar', webinar, { headers: headers });
  }
  nuevaMoocs(token: string, mooc: Moocs) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'seccion/moocs', mooc, { headers: headers });
  }

  editarPlaneaciones(token: string , planeacion: Planeaciones, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'seccion/planeacion/' +id, planeacion, { headers: headers });
  }
  editarPlaneacionesSesion(token: string, planeacion_ses: PlaneacionesSes, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'seccion/planeacion-ses/' +id, planeacion_ses, { headers: headers });
  }
  editarForo(token: string, foro: Foro, id:number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'seccion/foro/' +id, foro, { headers: headers });
  }
  editarWebinars(token: string, webinar: Webinars, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'seccion/webinar/' +id, webinar, { headers: headers });
  }
  editarMoocs(token: string, mooc: Moocs, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'seccion/moocs/' +id, mooc, { headers: headers });
  }
}
