import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Renovar } from 'src/app/models/licencias';
import { AdquisicionService } from 'src/app/services/adquisicion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-membresia-renovar',
  templateUrl: './membresia-renovar.component.html',
  styleUrls: ['./membresia-renovar.component.css']
})
export class MembresiaRenovarComponent implements OnInit {
  
  @Input () id_licencia;
  @Output () regresar = new EventEmitter<Number>();
  token: string = localStorage.getItem('token');
  
  public fecha =new Date().toISOString().slice(0,10); 
  public licencia = new Renovar();

  constructor(private adquisicionService: AdquisicionService, private router: Router) { }

  ngOnInit() {
  }

  activar(event: any) { 
    if (event.target.checked) {
      this.licencia.pagado = 1;
    } else {
      this.licencia.pagado = 0;
    }
  }

  guardar(form: NgForm) {
    if(form.valid){
      this.licencia.id_license = this.id_licencia;
      if(this.licencia.pagado==0) this.licencia.status = 0;
      console.log(this.licencia);
      this.adquisicionService.renovarLicencia(this.token, this.licencia).subscribe((response: any) => {
        console.log(response);
          Swal.fire({
            type: 'success',
            title: 'Licencia renovada ',
            showConfirmButton: false,
            timer: 2000
          });
          this.regresar.emit(1);
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al renovar la licencia:' + error.error.message
          });
        });
    }
    else{
      Swal.fire({
        type: 'error',
        title: '',
        text: 'No has llenado todos los campos.'
      });
    }
  }

}
