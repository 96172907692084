import { ContactForm } from './../../models/form-user';
import { ListaUsersService } from './../../services/lista-users.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
})
 export class UserCreateComponent implements OnInit {
  ContactModel = new ContactForm();
  public listauser: any = {};
  private token: string = localStorage.getItem('token');
  constructor(private listausersService: ListaUsersService, private router: Router) {
  }

  ngOnInit() {
  }

  // Funcion que se activa para guardar un nuevo teacher
  crearUser(form: NgForm) {
    if (form.valid) {
      console.log(form.value);
      this.ContactModel = form.value;
      this.ContactModel.peticion = "0";  
      console.log(this.ContactModel);  
      this.listausersService.addTeacher(this.token, this.ContactModel).subscribe(
      (response: any) => {
        Swal.fire({
          type: 'success',
          title: 'Usuario creado ',
          showConfirmButton: false,
          timer: 1500
        });
        this.router.navigate(['lista-users']);
      }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al crear el usuario:' + error.error.message
          });
        // this.router.navigate(['lista-users']);
      });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
     }
  }
  // Funcion que se activa cuando no se desea realizar ninguna accion 
  cancelar(){
    this.router.navigate(['lista-users']);
  }

}
