import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GameDescription, GamePrograma, GameRecurso, GameAbility, GameRuta } from '../models/game';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { config } from './config';
import { Licencia, LicenciaEdit, Renovar } from '../models/licencias';

@Injectable({
  providedIn: 'root'
})
export class AdquisicionService {

  public url = config.url;

  constructor(public http: HttpClient) { }

  getSecciones(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'adquisicion/secciones', { headers: headers });
  }
  getSource(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'adquisicion/source', { headers: headers });
  }

  getLicencias(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'adquisicion/license', { headers: headers });
  }

  getLicencia(token: string, id_licencia: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'adquisicion/license/' + id_licencia, { headers: headers });
  }

  crearLicencia(token: string, licencia: Licencia) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'adquisicion/license', licencia, { headers: headers });
  }
  enviarInvitaciones(token: string, correos) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'adquisicion/invite', correos, { headers: headers });
  }
  editarLicencia(token: string, licencia: LicenciaEdit) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'adquisicion/license', licencia, { headers: headers });
  }
  renovarLicencia(token: string, licencia: Renovar) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + 'adquisicion/license', licencia, { headers: headers });
  }
  uploadFile(fileToUpload: File, id_licencia) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    var formData: FormData = new FormData();
    formData.append('excel', fileToUpload, fileToUpload.name);
    // Peticion
    return this.http.post(this.url + 'adquisicion/invite-excel/' + id_licencia, formData, { headers: headers });
  }
  cancelarInvitacion(token: string, id, status) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'adquisicion/invite/' + id, status, { headers: headers });
  }
  cancelarUsuario(token: string, id, status) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.put(this.url + 'adquisicion/user/' + id, status, { headers: headers });
  }
  catState(token) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/estados`;
    return this.http.get(url,  { headers: headers });
  }
  catMunicipio(token, estado) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/municipios/` + estado;
    return this.http.get(url,  { headers: headers });
  }
  catCCTState(token, datos) {
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Peticion
    let url = this.url + `cat/cct-state`;
    return this.http.post(url, datos, { headers: headers });
  }

}
