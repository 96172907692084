import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Actividad, Materiales, Modulo, Mooc } from 'src/app/models/moocs';
import { CapacitacionService } from 'src/app/services/capacitacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mooc-crear',
  templateUrl: './mooc-crear.component.html',
  styleUrls: ['./mooc-crear.component.css']
})
export class MoocCrearComponent implements OnInit {

    private mooc = new Mooc();
    Arr = Array;

    private section = 0; 
    private id_mooc = 0;
    public nummodulos = 0;
    private tags:any = [];
    private listTags: any;
    private listTemas:any;
    private listAutores:any;
    private token: string = localStorage.getItem('token');
    private today: any;
    private mautores = [0];
    public arrayActividades = [];
    public modulos_save = [];
    imgmooc = null;

  constructor(private capacitacionService: CapacitacionService, private router: Router, private renderer: Renderer2) { 

  }

  ngOnInit() {
    this.refreshTags();
    this.refreshTemas();
    this.capacitacionService.getAutores().subscribe((response: any) => {
      this.listAutores = response;
      //console.log(this.listAutores);
    });
  }

  otroAutor(){
    this.mautores.push(0);
  }

  quitarAutor(index:number){
    var rm = this.mautores.splice(index, 1);
  }

  async crearTema(){
    let newCategoria:string;
    await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escriba aqui el tema',
      showCancelButton: true
    }).then((text) => {
        if (text.value) {
          newCategoria = text.value;
        } else{
          newCategoria = "#no#";
        }
      })
    if(newCategoria != "#no#"){
      let data= {'tema':newCategoria}; //console.log(data);
      this.capacitacionService.postTemas(data, this.token).subscribe((response: any) => {
        this.refreshTemas();                
        //console.log(response);
        Swal.fire("",response.message,"success");
        this.mooc.id_tema = "";
      });
    }
  }

  refreshTemas(){
    this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
      this.listTemas = response.temas;
      //console.log(this.listTemas);
    });
  }

  SelectTags(e:any, tag:any){
    let clase = e.target.className;
    if(clase == "unselected"){
      this.renderer.removeClass(e.target, 'unselected');
      this.renderer.addClass(e.target, 'selected');
      if(!this.tags.includes(tag))
        this.tags = this.tags.concat([tag]);
      }
      if(clase == "selected"){
        this.renderer.removeClass(e.target, 'selected');
        this.renderer.addClass(e.target, 'unselected');
        if(this.tags.includes(tag))
          var rm = this.tags.splice(this.tags.indexOf(tag), 1);
      }
  }

  async crearTag(){
    let newtag:string;
    await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escriba aqui el Tag',
      showCancelButton: true
    }).then((text) => {
      if (text.value) { 
        newtag = text.value;
      } else{
        newtag = "#no#"; 
      }
     })
     if(newtag != "#no#"){
      let data= {'tag':newtag}; //console.log(data);
      this.capacitacionService.postTags(data, this.token).subscribe((response: any) => {
        this.refreshTags();
        //console.log(response);
        Swal.fire("",response.message,"success");
          this.tags = [];
      });
     }
  }

  refreshTags(){
    this.capacitacionService.getTags(this.token).subscribe((response: any) => {
      this.listTags = response.tags;
      //console.log(this.listTags);
    });
  }

  selectImage(e:any){
    this.imgmooc = e.target.files;
  }

  cancelar() {
    this.router.navigate(['gestormoocs']);
  }

  createmooc(){
    this.mooc.autores = this.mautores.toString();
    this.mooc.tags = this.tags.toString();
    //console.log(this.mooc);

    let fd = new FormData();
      fd.append('image', this.imgmooc[0], this.imgmooc[0].name);
      fd.append('titulo', this.mooc.titulo);
      fd.append('id_tema', this.mooc.id_tema);
      fd.append('tags', this.tags.toString());
      fd.append('fecha_inicio', this.mooc.fecha_inicio.toString());
      fd.append('duracion', this.mooc.duracion.toString());
      fd.append('descripcion', this.mooc.descripcion);
      fd.append('nivel', this.mooc.nivel);
      fd.append('idioma', this.mooc.idioma);
      fd.append('autores', this.mooc.autores);
      fd.append('certificado', this.mooc.certificado.toString());
      fd.append('especial', this.mooc.especial.toString());
      fd.append('cobrar', this.mooc.cobrar.toString());
      fd.append('precio', this.mooc.precio.toString());
      fd.append('status', this.mooc.status.toString());
    //console.log(fd);

    this.capacitacionService.crearMooc(this.token, fd).subscribe((response: any) => {  
      Swal.fire("",response.message,"success");
      this.id_mooc = response.id_mooc;
      this.section=1;
    }, error => {
      Swal.fire("",error.message,"error");
    });

    }

  guardarModulo(form: NgForm, m) {
    Swal.showLoading();
    if(form.valid && this.id_mooc!=0) {
      if(!this.modulos_save.includes(m)){

        let modulo = new Modulo();
        modulo = form.value;
        modulo.id_mooc = this.id_mooc;
        modulo.modulo = m;

        let mod_act = [];
        this.arrayActividades.forEach(aa => {
          if(aa.modulo == m) mod_act.push(aa);
        });
        modulo.actividades = mod_act;
        modulo.actividades.forEach((ma, aindex) => {
          ma.actividad = aindex+1;
          //console.log(aindex, ma.actividad);
          if(ma.materiales){
            ma.materiales.forEach(mam => {
              mam.actividad = ma.actividad;
            });
          }
        });
        //console.log(modulo);
        this.modulos_save.push(m);

        this.capacitacionService.crearModulo(this.token, modulo).subscribe((response: any) => {
          let ids = response.ids_actividades;
          //Asignar el id de la actividad y enviar cada material.
          modulo.actividades.forEach(ma => {
            if(ma.materiales){
              ma.materiales.forEach(mat => {
                ids.forEach(i => {
                  if(mat.actividad == i.actividad){
                    mat.id_actividad = i.id_actividad;
                  }
                });
                if(mat.id_actividad){
                  let fd1 = new FormData();
                  fd1.append('file', mat.file[0], mat.file[0].name);
                  fd1.append('nombre', mat.nombre);
                  fd1.append('duracion', mat.duracion.toString());
                  fd1.append('actividad', mat.actividad.toString());
                  fd1.append('id_actividad', mat.id_actividad.toString());
                  fd1.append('orden', mat.orden.toString());
                  fd1.append('tipo', mat.tipo);
                  fd1.append('status', mat.status.toString());
                  //console.log(fd1);
                  this.capacitacionService.crearMaterial(this.token, fd1).subscribe((response: any) => {  
                    Swal.fire("",response.message,"success");
                  }, error => {
                    Swal.fire("",error.message,"error");
                  });
                }
              });
            }
          });

          Swal.fire("",response.message,"success");
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error:' + error.error.message
          });
        });

      } else{
        Swal.fire({
          type: 'error',
          title: '',
          text: 'Este módulo ya fue guardado, si necesitas hacer algún cambio ve a la sección de "Gestor Moocs" para editar el mooc'
        });
      }
    }
    else{
      Swal.fire({
        type: 'error',
        title: '',
        text: 'No has llenado todos los campos.'
      });
    }
  }

  addActividad(modulo){
    let acti = new Actividad();
    acti.actividad = (this.arrayActividades.length==0) ? 1 : this.arrayActividades.length+1;
    acti.modulo = modulo;
    this.arrayActividades.push(acti);
    //console.log(this.arrayActividades);
  }

  addMaterial(index){
    if (this.arrayActividades[index].materiales==undefined) this.arrayActividades[index].materiales = [];
    this.arrayActividades[index].materiales.push(new Materiales());
    //console.log(this.arrayActividades);
  }

  uploadArchivo(e:any, acti, mat ){
    this.arrayActividades[acti].materiales[mat].file = e.target.files;
  }

  finalizar(){
    Swal.fire({
      title: '¿Seguro quieres salir de la creación del mooc?',
      text: "Asegurate de haber guardado cada uno de los módulos antes de continuar",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['gestormoocs']);
      }
    })
  }


}
