import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GameService } from 'src/app/services/games.service';
import { Game } from '../../models/game';


@Component({
  selector: 'app-card-game',
  templateUrl: './card-game.component.html'
})
export class CardGameComponent implements OnInit {

  @Input() game = new Game();
  private token: string = localStorage.getItem('token');
  constructor(private router: Router, private gameService: GameService) { }

  ngOnInit() {
    console.log(this.game);
  }
  mostrar(opc: string) {
    console.log(opc);
  }
  verFicha() {
    this.router.navigate(['juego', this.game.slug]);
  }
  activar(event: any) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.gameService.eliminarFicha(this.token, this.game.slug).subscribe(
        (response: any) => {
          console.log('se rescato ');
          this.router.navigate(['juegos']);
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.gameService.eliminarFicha(this.token, this.game.slug).subscribe(
        (response: any) => {
          console.log('se borro ');
          this.router.navigate(['juegos']);
        }, error => {
          console.log(error);
        }
      );
    }
  }

}
