import { GameService } from './../../services/games.service';
import { GameRuta } from './../../models/game';
import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TagsService } from './../../services/tags.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rutas-didacticas',
  templateUrl: './rutas-didacticas.component.html',
})
export class RutasDidacticasComponent implements OnInit {
  @Input()gameId: number;
  @Input() update: number;
  @Input() rutaIndex: number;
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  public procesos: string[] = [];
  public gameRuta = new GameRuta();
  public Editor = ClassicEditor;
  private up = '0';
  private token = localStorage.getItem('token');
  @Output() addRuta: EventEmitter<number>;
  @Output() addRuta2: EventEmitter<number>;


  constructor(private gameService: GameService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private tagsService: TagsService) {
              this.addRuta = new EventEmitter();
              this.addRuta2= new EventEmitter();
              this.activatedRoute.params.subscribe(params => {
                if (Object.keys(params).length !== 0) {
                  this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
                    console.log(this.rutaIndex);
                    this.gameRuta.game_id = response.game.id;
                    this.gameRuta.ruta_id = response.rutas.id;
                    console.log(this.gameRuta.ruta_id);
                    this.gameId = response.game.id;
                    console.log(response);
                    if (response.ruta === null) {
                      this.gameRuta.ruta = {
                            titulo: '',
                            subtitulo: '',
                            argumentacion: ''
                      };
                      this.gameRuta.procesos = '';
                      this.gameRuta.tags = '';
                    } else {
                      this.gameRuta.ruta = {
                        titulo: response.rutas[this.rutaIndex].titulo,
                        subtitulo: response.rutas[this.rutaIndex].subtitulo,
                        argumentacion: response.rutas[this.rutaIndex].argumentacion
                      };
                      console.log(response.rutas[this.rutaIndex].procesos);
                      let procesosDB: string[] = [];
                      for (let proceso of response.rutas[this.rutaIndex].procesos) {
                        procesosDB.push(proceso.descripcion);
                      }
                      this.gameRuta.procesos =  procesosDB.join('$');

                      this.gameRuta.ruta_id = response.rutas[this.rutaIndex].id;
                      console.log(this.gameRuta.ruta_id);
                      this.gameRuta.tags = response.rutas[this.rutaIndex].tags;
                      this.procesos = this.gameRuta.procesos.split('$');
                      this.up = '1';
                      const regex = /\s*,\s*/;
                      this.tags = this.gameRuta.tags.split(regex);
                      this.tags = this.tags.filter((element) => {
                        return element !== '';
                      });
                    }
                  }, error => {
                    console.log(error);
                  });
                }
              });
              this.tagsService.getTags().subscribe(
                response => {
                  this.tagsDB = Object.values(response);
                  let auxTag = [];
                  for (const tag of this.tagsDB) {
                    auxTag.push(tag.nombre);
                  }
                  this.tagsDB = auxTag;
                }, error => {
                  console.log(error.message);
                }
              );
   }

  ngOnInit() {
  }
  addProceso(proceso) {
    console.log(proceso.value);
    if (proceso !== '') {
      this.procesos.push(proceso.value);
    }
    proceso.value = '';
    this.gameRuta.procesos = this.procesos.join(',');
    console.log(this.gameRuta.procesos);
  }
  deleteRuta( idRuta) {
    this.gameService.eliminarRuta(this.token, idRuta, this.gameId).subscribe(
        (response: any) => {
          if (response) {
            Swal.fire({
              type: 'success',
              title: 'Se elimino la ruta',
              showConfirmButton: false,
              timer: 1500
            });
            this.router.navigate(['juegos']);
          }
          // this.router.navigate(['lista-users']);

        }, error => {
          console.log(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ruta no encontrada',
            timer: 1500
          });
          // this.router.navigate(['videos']);
          // en caso de que el usuario no se puede borrar porque no existe te redirecciona al inicio 
        });

  }

  agregarRuta( form: NgForm ) {
    console.log(form.value);
    if (form.valid) {
      let gameDataRuta = new GameRuta();
      if (this.gameId !== 0 && this.gameId !== undefined) {
        gameDataRuta.game_id = this.gameId;
        gameDataRuta.ruta_id = this.gameRuta.ruta_id;
        console.log(gameDataRuta.ruta_id);
        if (this.update === 1 && this.up === '1') {
          gameDataRuta.peticion = '1';
        }
        const ruta = {
          titulo: form.value.titulo,
          subtitulo: form.value.subtitulo,
          argumentacion: form.value.argumentacion
        };
        gameDataRuta.procesos = this.procesos.join('$');
        gameDataRuta.ruta = ruta;
        gameDataRuta.tags = form.value.tags;
        console.log(gameDataRuta);
        this.gameService.addRuta(this.token, gameDataRuta).subscribe(
          response => {
            Swal.fire({
              type: 'success',
              title: 'Se creo la ruta didactica ',
              showConfirmButton: false,
              timer: 1500
            });
            
            this.addRuta2.emit(1);
            this.addRuta.emit(1);
            
            
            // this.router.navigate(['juego', this.game.slug]);
          }, error => {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear la ruta didactica:' + error.error.message
            });
          }
        );
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Escoge un juego para crear la ficha'
        });
      }
    } else {
      console.log(form);
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
    }
  
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd = this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        const caracter = this.gameRuta.tags[this.gameRuta.tags.length - 1];
        console.log(caracter);
        this.gameRuta.tags = this.gameRuta.tags + `, ${arrTags[arrTags.length - 2].trim()}`;
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.gameRuta.tags = '';
      if (this.tags.length > 0) {
        this.gameRuta.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.gameRuta.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.gameRuta.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.gameRuta.tags = '';
      if (this.tagsNew.length > 0) {
        this.gameRuta.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.gameRuta.tags = `${this.tags.join(',')}`;
        } else {
          this.gameRuta.tags = '';
        }
      }
    }
  }

  eliminarProceso(idx: number) {
    const len = this.procesos.length;
    if (delete this.procesos[idx]) {
      const arrayFiltered = this.procesos.filter((element) => {
        return element != null;
      });
      this.procesos = arrayFiltered;
      this.gameRuta.procesos = '';
      if (this.procesos.length > 0) {
        this.gameRuta.procesos = `${this.procesos.join(',')}`;
      }
    }
  }

}
