export class dataUser {
    public correo?:string;
    public nombre?:string;
    public cct?: any;
    public source?: string;
    public fechaIn?: string;
    public fechaFi?: string;
    public password?: string;
   
}
export class dataUserLicense {
    public correo?: string;
    public nombre?: string;
    public cct?: any;
    public user_rol?: string;
    public user_rol_id?: string;
    public fechaIn?: string;
    public fechaFi?: string;

}
export class usersAU {
    public correo?: string;
    public nombre?: string;
    public cct?: any;
    public user_rol?: string;
    public user_rol_id?: string;
    public fechaIn?: string;
    public fechaFi?: string;
    public licencia;
    public admin;

}
export class newStudent{
    public cct_id;
    public username;
    public password;
    public fullname;
    public group_id;
    public group_name;
    public user_id;
}