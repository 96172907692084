// Clase que se exporta para poder obtener los valores del formulario
export class ContactForm {
    public first_name: string;
    public last_name: string;
    public email: string;
    public role_id: number;
    public password: string;
    public status: number;
    public peticion: string;
    public id?: number;
    public confirm_password : string;
    public deleted ?: number;
}
