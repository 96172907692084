import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('.nav-link').click(function () {        
        if (!($("#logo").hasClass("logo-nav"))) {
          $('#logo').addClass('logo-nav');
        } else {
          $('#logo').removeClass('logo-nav');
        }

      });
    });
  }

}
