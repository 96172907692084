export class OtrosRecursos {
        public id: number;
        public nombre = '';
        public descripcion = '';
        public link = '';
        public video = '';
        public status?: boolean;
        public tags = '';
        public img?= '';
        public pdf?= '';
}
export class OtroRecurso {
        public nombre = '';
        public descripcion = '';
        public link = '';
        public video = '';
        public status?: boolean;
        public tags = '';
        public peticion = '';
        public img? = '';
        public pdf?= '';
}
export class LibrosTexto {
        public tema = '';
        public libro = '';
        public grado = '';
        public bloque = '';
        public pag = '';
        public tags = '';
        public link = '';
        public status: boolean;
        public peticion = 0;
}
export class Links {
        public nombre = '';
        public link = '';
        public grado = '';
        public status: boolean;
}

