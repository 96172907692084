import { OtrosRecursos } from './../../models/otros-recursos';
import { OtrosRecursosService } from './../../services/otros-recursos.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recursos-externos',
  templateUrl: './recursos-externos.component.html',
})
export class RecursosExternosComponent implements OnInit {
  otrosrecursos: OtrosRecursos[] = [];
  private refresh:any;
  token: string = localStorage.getItem('token');
  constructor(private otrosrecursosService: OtrosRecursosService, private router: Router) {
    this.otrosrecursosService.mostrarRecursos(this.token).subscribe((response: any) => {
      this.otrosrecursos = response.message;
      console.log(response.message);
      console.log(this.otrosrecursos[1]);
    }, error => {
      console.log(error.error.message);
    });
  }
  ngOnInit() {
    this.refresh = localStorage.getItem('refresh');
    if(this.refresh === "false"){
      location.reload();
      localStorage.removeItem('refresh');      
    }
  }
  addRecurso() {
    this.router.navigate(['recurso/nuevo']);
  }
  deleteRecurso(id, idx: number) {
    this.otrosrecursosService.eliminarRecurso(this.token, id).subscribe(
      (response: any) => {
        console.log(response);
        if (response) {
          this.otrosrecursos[idx].status = false;
          console.log(this.otrosrecursos[idx]);
          Swal.fire({
            type: 'success',
            title: 'Se desactivo el usuario',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.router.navigate(['lista-recursos']);

      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Este usuario ya esta inactivo',
          timer: 1500
        });
        this.router.navigate(['lista-recursos']);
        // en caso de que el usuario no se puede borrar porque no existe te redirecciona al inicio 
      });
  }
  updateRecurso(id) {
    this.router.navigate(['update-recurso', id]);
  }
}
