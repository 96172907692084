import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { autor } from '../../models/autor';
import { imagenesBlog } from '../../services/config';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blogAutor',
  templateUrl: './blogAutor.component.html'
})

export class blogAutorComponent implements OnInit {

  private urlIMG = imagenesBlog.url;
  
  private autores:any;
  public autor = new autor();

  public visibleAlta: boolean = false;
  public visibleEdit: boolean = false;

  private nombre:string;
  private link: string;
  private resumen: string;
  private foto: Array <File>;
  private flag:boolean = true;

  private IDEdit: number;
  private nombreEdit:string;
  private linkEdit: string;
  private resumenEdit: string;
  private isFotoEditAviable : boolean = false;
  private fotoEdit: Array <File>;
  private flagEdit:boolean = true;
  private persona: string;

  constructor(private blogService: BlogService, private activatedRoute: ActivatedRoute,){
    this.activatedRoute.params.subscribe(params => {
      this.persona = params.AoI=='A'?'Autor' : 'Instructor'; 
    });
  }  
  
  selectImage(e:any){
    this.foto = e.target.files;
  }

  selectImageEdit(e:any){
    this.fotoEdit = e.target.files;
  }

  registrarAutor(){
    let fd = new FormData();
    if(this.foto != undefined)
    {
      if(this.foto[0].type == "image/jpeg" || this.foto[0].type == "image/png"){
        this.flag = true;
        // if(this.foto[0].size > 21000 ){
        //   Swal.fire("","La imagen debe pesar menos de 20KB","warning");
        //   this.flag=false;
        // }
        // else{
        //   this.flag=true;
        // }
      }
      else{
        Swal.fire("","Debe seleccionar una imagen jpg o png","warning");
        this.flag=false;
      }
    }
    if(this.nombre != undefined && this.link != undefined && this.resumen != undefined && this.foto != undefined && this.flag){
      fd.append('image', this.foto[0], this.foto[0].name);
      fd.append('nombre', this.nombre);
      fd.append('enlace', this.link);
      fd.append('resumenAutor', this.resumen);
      //subir al service
      this.blogService.postAutor(fd).subscribe((response :any) => {
        console.log('response:',response);
        this.nombre = undefined;
        this.link = undefined;
        this.resumen = undefined;
        this.foto = undefined;
        this.flag = true;
        this.visibleAlta = false;
        this.refreshListAutor();
        Swal.fire("","Autor registrado","success");
      })
    }
  }

  refreshListAutor(){
    this.blogService.getAutores().subscribe((response: any) => {
      this.autores = response;
    }); 
  }

  editarAutor(index:number){
    this.visibleEdit = true;
    this.IDEdit = this.autores[index].ID;
    this.nombreEdit = this.autores[index].nombre;
    this.linkEdit = this.autores[index].enlace;
    this.fotoEdit = this.autores[index].foto;
    this.resumenEdit = this.autores[index].resumenAutor;
    this.isFotoEditAviable = false;
  }

  uploadStatusAutor(){
    let b = {"ID":this.IDEdit,"status":false}
    console.log(b);
    Swal.fire({
      title: '¿Seguro quieres eliminar este autor?',
      text: "no podras deshacer esto!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo'
    }).then((result) => {
      if (result.value) {
        this.blogService.postStatusAutores(b).subscribe((response: any) => {
          this.refreshListAutor();
          this.visibleEdit = false;
        });
        Swal.fire(
          'Eliminado!',
          'Ya no visualizaras a este autor, pero seguira apareciendo en los articulos.',
          'success'
        )
      }
    })
  }

  uploadAutorEditNoPhoto(){
    if(this.nombreEdit != undefined && this.linkEdit != undefined && this.resumenEdit != undefined && !this.isFotoEditAviable){
      this.autor.ID = this.IDEdit;
      this.autor.nombre = this.nombreEdit;
      this.autor.enlace = this.linkEdit;
      this.autor.foto = this.fotoEdit;
      this.autor.resumenAutor = this.resumenEdit;

      this.blogService.postEditAutorNoPhoto(this.autor).subscribe((response: any) => {
        this.nombreEdit = undefined;
        this.linkEdit = undefined;
        this.resumenEdit = undefined;
        this.fotoEdit = undefined;
        this.visibleEdit = false;
        this.refreshListAutor();
        Swal.fire("","Autor actualizado","success");
      });
    }
  }

  uploadAutorEditWPhoto(){
    let fdEdit = new FormData();
    if(this.fotoEdit != undefined)
    {
      if(this.fotoEdit[0].type == "image/jpeg" || this.fotoEdit[0].type == "image/png"){
        this.flagEdit = true;
      }
      else{
        Swal.fire("","Debe seleccionar una imagen jpg o png","warning");
        this.flagEdit=false;
      }
      // if(this.fotoEdit[0].size > 21000 ){
      //   Swal.fire("","La imagen debe pesar menos de 20KB","warning");
      //   this.flagEdit=false;
      // }
      // else{
      //   this.flagEdit=true;
      // }
    }
    if(this.nombreEdit != undefined && this.linkEdit != undefined && this.resumenEdit != undefined && this.isFotoEditAviable && this.fotoEdit != undefined && this.flagEdit){
      fdEdit.append('image', this.fotoEdit[0], this.fotoEdit[0].name);
      fdEdit.append('ID', this.IDEdit.toString());
      fdEdit.append('nombre', this.nombreEdit);
      fdEdit.append('enlace', this.linkEdit);
      fdEdit.append('resumenAutor', this.resumenEdit);

      this.blogService.postEditAutorWPhoto(fdEdit).subscribe((response: any) => {
        this.nombreEdit = undefined;
        this.linkEdit = undefined;
        this.resumenEdit = undefined;
        this.fotoEdit = undefined;
        this.flagEdit = true;
        this.visibleEdit = false;
        this.isFotoEditAviable = false;
        this.refreshListAutor();
        Swal.fire("","Autor actualizado","success");
       });
    }
  }

  ngOnInit() {
    this.visibleAlta = false;   
    this.visibleEdit = false; 
    this.flag = true; 
    this.flagEdit = true;
    this.isFotoEditAviable = false;
    this.refreshListAutor();
  }
}
