import { Component, OnInit } from '@angular/core';
import { dataUser } from '../../models/findUser';
import { UsersTttk } from 'src/app/services/licencias-ttteka.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licencias-ttteka',
  templateUrl: './licencias-ttteka.component.html',
  styleUrls: ['./licencias-ttteka.component.css']
})
export class LicenciasTTtekaComponent implements OnInit {
  token = localStorage.getItem('token');
  list: any[] = [];
  dataU = new dataUser();

  constructor(private ttkU: UsersTttk) { }

  ngOnInit() {
  }

  getUsers() {
    this.ttkU.getUsersLicense(this.token,this.dataU).subscribe(
      response => {
        this.list = response["usuarios"];
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

}
