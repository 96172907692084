import { OtroRecurso } from './../../models/otros-recursos';
import { OtrosRecursosService } from './../../services/otros-recursos.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TagsService } from 'src/app/services/tags.service';




@Component({
  selector: 'app-create-recurso',
  templateUrl: './create-recurso.component.html',
  styleUrls: ['./create-recurso.component.css']
})
export class CreateRecursoComponent implements OnInit {
  public pdf64: string;
  public imageUrl: string;
  public Editor = ClassicEditor;
  otrosrecursos = new OtroRecurso();
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  private token = localStorage.getItem('token');
  public pdfUrl: string;
  constructor(private otrosrecursosService: OtrosRecursosService, private router: Router, private tagsService: TagsService) {
    this.tagsService.getTags().subscribe(
      response => {
        this.tagsDB = Object.values(response);
        let auxTag = [];
        for (const tag of this.tagsDB) {
          auxTag.push(tag.nombre);
        }
        this.tagsDB = auxTag;
      }, error => {
        console.log(error.message);
      }
    );
  }

  ngOnInit() {
  }

  // Funcion que se activa para guardar un nuevo teacher
  crearRecurso(form: NgForm) {
    //console.log(form.value);
    if (form.valid) {
      this.otrosrecursos = form.value;
      if (!(localStorage.getItem('my64') === null)) {
        this.otrosrecursos.img = localStorage.getItem('my64');
      } else {
        this.otrosrecursos.img = null;
      }      
      if (!(localStorage.getItem('mypdf64') === null)) {
        this.otrosrecursos.pdf = this.pdf64;
      }else{
        this.otrosrecursos.pdf = null;
      }
      console.log(this.otrosrecursos);
      this.otrosrecursosService.addNewRecurso(this.token, this.otrosrecursos).subscribe(
        (response: any) => {
          Swal.fire({
            type: 'success',
            title: 'Usuario creado ',
            showConfirmButton: false,
            timer: 1500
          });
          localStorage.removeItem('my64');
          localStorage.removeItem('mypdf64');
          this.router.navigate(['lista-recursos']);
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al crear el usuario:' + error.error.message
          });
          // this.router.navigate(['lista-users']);
          localStorage.removeItem('my64');
        });
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
    }
  }
  changeTags(event) {
    console.log(event.target.value);
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd = this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        console.log(this.otrosrecursos.tags);
        const caracter = this.otrosrecursos.tags[this.otrosrecursos.tags.length - 1];
        console.log(caracter);
        console.log(this.otrosrecursos.tags);
        this.otrosrecursos.tags = this.otrosrecursos.tags + `, ${arrTags[arrTags.length - 2].trim()} `;//-2
        console.log(this.otrosrecursos.tags);
        console.log(event.target.value = '');
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.otrosrecursos.tags = '';
      if (this.tags.length > 0) {
        this.otrosrecursos.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.otrosrecursos.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.otrosrecursos.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.otrosrecursos.tags = '';
      if (this.tagsNew.length > 0) {
        this.otrosrecursos.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.otrosrecursos.tags = `${this.tags.join(',')}`;
        } else {
          this.otrosrecursos.tags = '';
        }
      }
    }
  }
  // Funcion que se activa cuando no se desea realizar ninguna accion 
  cancelar() {
    this.router.navigate(['lista-recursos']);
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  changeImage(event) {
    let files = event.target.files;
    let image = files[0];
    this.imageUrl = URL.createObjectURL(image);
    // this.cambioImagen = true;
    // return this.cambioImagen;

    this.toDataURL(this.imageUrl, function (dataUrl) {
      localStorage.setItem('my64', dataUrl);
      console.log(dataUrl);
    })

  }
  changePDF(event) {
    let files = event.target.files;
    let pdf = files[0];
    //this.pdfUrl = URL.createObjectURL(pdf);
    // this.cambioImagen = true;
    // return this.cambioImagen;

    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(pdf);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    let final64 = 'data:application/pdf;base64,' + btoa(binaryString);
    localStorage.setItem('mypdf64',"True");
    this.pdf64 = final64;
    //console.log(this.otrosrecursos);
  }




}
