import { Component, OnInit, Renderer2} from '@angular/core';
import { CapacitacionService } from '../../services/capacitacion.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { imagenesCapacitaciones } from '../../services/config';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-capacitacionCrearWebinar',
    templateUrl: './capacitacionCrearWebinar.component.html',
    styleUrls: ['./capacitacionCrearWebinar.component.css']
})

export class capacitacionCrearWebinar implements OnInit {

    private webinar:any;
    private tags:any = [];
    private listTags: any;
    private listTemas:any;
    private listAutores:any;
    private token: string = localStorage.getItem('token');
    private today: any;
    private validate: string = '';

    constructor(private capacitacionService: CapacitacionService, private renderer: Renderer2, private router: Router){
    } 

    selectImage(e:any){
        this.webinar.file = e.target.files;
    }

    uploadWebinar(){
        this.validate = '';
        if(this.webinar.titulo == '')
            this.validate = this.validate + ' El titulo no puede ser vacio.';
        if(this.webinar.id_tema == null)
            this.validate = this.validate + ' Selecciona algun tema.';
        if(this.tags.length == 0 || this.tags == null)
            this.validate = this.validate + ' Selecciona almenos un tag.';
        if(this.webinar.fecha == null)
            this.validate = this.validate + ' Selecciona un fecha.';
        if(this.webinar.duracion == null || this.webinar.duracion < 0)
            this.validate = this.validate + ' Ponga una duracion para el webinar en minutos.';
        if(this.webinar.descripcion == '')
            this.validate = this.validate + ' Agregue un descripcion.';
        if(this.webinar.autores.length == 0 || this.webinar.autores == null)
            this.validate = this.validate + ' Selecciona almenos un instructor.';
        if(this.webinar.precio == null || this.webinar.precio < 0)
            this.validate = this.validate + ' Agregue un precio al Webinar.';
        if(this.webinar.codigo == '')
            this.validate = this.validate + ' Agregue un código para el Webinar.';
        if(this.webinar.password == '')
            this.validate = this.validate + ' Agregue el password para el Webinar.';
        if(this.webinar.file == null || this.webinar.file == undefined)
            this.validate = this.validate + ' Seleccion una imagen.';

        if(this.validate == ''){
            let fd = new FormData();
                fd.append('image', this.webinar.file[0], this.webinar.file[0].name);
                fd.append('status', this.webinar.status);
                fd.append('titulo', this.webinar.titulo);
                fd.append('id_tema', this.webinar.id_tema);
                fd.append('tags', this.tags.toString());
                fd.append('fecha', this.webinar.fecha);
                fd.append('duracion', this.webinar.duracion+" minutos");
                fd.append('descripcion',this.webinar.descripcion);
                fd.append('autores', this.webinar.autores.toString());
                fd.append('certificado', this.webinar.certificado);
                fd.append('especial', this.webinar.especial);
                fd.append('cobrar', this.webinar.cobrar);
                fd.append('precio',this.webinar.precio);
                fd.append('link', this.webinar.link);
                fd.append('codigo', this.webinar.codigo);
                fd.append('password', this.webinar.password);
            let json ={
                'status': this.webinar.status,
                'titulo': this.webinar.titulo,
                'id_tema': this.webinar.id_tema,
                'tags': this.tags.toString(),
                'fecha': this.webinar.fecha.replace('T',' '),
                'duracion': this.webinar.duracion+" minutos",
                'descripcion': this.webinar.descripcion,
                'autores': this.webinar.autores.toString(),
                'certificado': this.webinar.certificado,
                'especial': this.webinar.especial,
                'cobrar': this.webinar.cobrar,
                'precio': this.webinar.precio, 
                'link': this.webinar.link,
                'codigo': this.webinar.codigo,
                'password': this.webinar.password
            };
            console.log(json);
            this.capacitacionService.postWebinar(fd, this.token).subscribe((response: any) => {  
                Swal.fire("",response.message,"success");
                this.refreshData();
                this.router.navigate(['gestorwebinar']);
            });
        }else{
            Swal.fire("",this.validate,"warning");
        }
    }

    otroAutor(){
        this.webinar.autores.push(0);
    }

    quitarAutor(index:number){
        var rm = this.webinar.autores.splice(index, 1);
    }

    async crearTema(){
        let newCategoria:string;
        await Swal.fire({
            input: 'textarea',
            inputPlaceholder: 'Escriba aqui el tema',
            showCancelButton: true
          }).then((text) => {
            if (text.value) {
              newCategoria = text.value;
            } else{
              newCategoria = "#no#";
            }
        })
        if(newCategoria != "#no#"){
            let data= {'tema':newCategoria}; console.log(data);
            this.capacitacionService.postTemas(data, this.token).subscribe((response: any) => {
                this.refreshTemas();                
                console.log(response);
                Swal.fire("",response.message,"success");
                this.webinar.tema = "";
            });
        }
    }

    refreshTemas(){
        this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
            this.listTemas = response.temas;
            console.log(this.listTemas);
        });
    }

    SelectTags(e:any, tag:any){
        let clase = e.target.className;
        if(clase == "unselected"){
            this.renderer.removeClass(e.target, 'unselected');
            this.renderer.addClass(e.target, 'selected');
            if(!this.tags.includes(tag))
                this.tags = this.tags.concat([tag]);
        }
        if(clase == "selected"){
            this.renderer.removeClass(e.target, 'selected');
            this.renderer.addClass(e.target, 'unselected');
            if(this.tags.includes(tag))
                var rm = this.tags.splice(this.tags.indexOf(tag), 1);
        }
    }

    async crearTag(){
        let newtag:string;
        await Swal.fire({
            input: 'textarea',
            inputPlaceholder: 'Escriba aqui el Tag',
            showCancelButton: true
          }).then((text) => {
            if (text.value) { 
              newtag = text.value;
            } else{
              newtag = "#no#"; 
            }
        })
        if(newtag != "#no#"){
            let data= {'tag':newtag}; console.log(data);
            this.capacitacionService.postTags(data, this.token).subscribe((response: any) => {
                this.refreshTags();
                console.log(response);
                Swal.fire("",response.message,"success");
                this.tags = [];
            });
        }
    }

    refreshTags(){
        this.capacitacionService.getTags(this.token).subscribe((response: any) => {
            this.listTags = response.tags;
            console.log(this.listTags);
        });
    }

    refreshData(){
        this.validate = '';
        this.webinar = {
            status: 1,
            id_tema: null,
            titulo: "",
            tags: [0],
            fecha: this.today,
            duracion: 60,
            descripcion: "",
            autores: [0],
            certificado: 1,
            especial: 0, //¿El webinar solo lo podrán ver miembros de una campaña?
            cobrar: 0, //¿El webinar tendrá un costo extra para los miembros de paquetes básico y premium?
            precio: '500.00', 
            link: '',
            codigo: '',
            password: '',
            file: null
        }
    }

    ngOnInit() {
        this.today = (new Date()).toISOString().slice(0, 10)+"T13:00";
        this.refreshData();
        this.refreshTags();
        this.refreshTemas();
        this.capacitacionService.getAutores().subscribe((response: any) => {
            this.listAutores = response;
            console.log(this.listAutores);
        });
    }
}