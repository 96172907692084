
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { AuthGuard } from '../guards/auth.guard';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuariosDetalleComponent } from './usuarios-detalle/usuarios-detalle.component';
import { LicenciasComponent } from './licencias/licencias.component';
import { JuegosComponent } from './juegos/juegos.component';
import { JuegoDetalleComponent } from './juego-detalle/juego-detalle.component';
import { ListaUsersComponent } from './lista-users/lista-users.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { AbilitiesComponent } from './abilities/abilities.component';
import { RutasDidacticasComponent } from './rutas-didacticas/rutas-didacticas.component';
import { RecursosExternosComponent } from './recursos-externos/recursos-externos.component';
import { UpdateRecursoComponent } from './update-recurso/update-recurso.component';
import { CreateRecursoComponent } from './create-recurso/create-recurso.component';
import { LicenciasLtComponent } from './licencias-lt/licencias-lt.component';
import { UsersAuComponent } from './users-au/users-au.component';
import { AuDetailComponent } from './au-detail/au-detail.component';
import { AuStudentsComponent } from './au-students/au-students.component';
import { AuAddStudentComponent } from './au-add-student/au-add-student.component';
import { LicenciasTTtekaComponent } from './licencias-ttteka/licencias-ttteka.component';

import { blogAutorComponent } from './blog-autor/blogAutor.component'; //blog
import { blogCrearArticuloComponent } from './blog-crearArticulo/blogCrearArticulo.component';
import { blogEditarArticuloComponent } from './blog-editarArticulo/blogEditarArticulo.component';
import { foroComponent } from './foro/foro.component';

import { capacitacionCrearWebinar } from './capacitacion-crearWebinar/capacitacionCrearWebinar.component'; //Capacitacion
import { capacitacionGestorWebinar } from './capacitacion-gestorWebinar/capacitacionGestorWebinar.component';

import { NuevoJuegoComponent } from './nuevo-juego/nuevo-juego.component';
import { StudentsListComponent } from './students-list/students-list.component';
import { LicenciasTttekaDetalleComponent } from './licencias-ttteka-detalle/licencias-ttteka-detalle.component';
import { MembresiaComponent } from './membresia/membresia.component';
import { MembresiaEditarComponent } from './membresia-editar/membresia-editar.component';
import { SeccionesLtComponent } from './secciones-lt/secciones-lt.component';
import { LibroNuevoComponent } from './libro-nuevo/libro-nuevo.component';
import { LibrosComponent } from './libros/libros.component';
import { UpdateLibroComponent } from './update-libro/update-libro.component';
import { LinkLibrosComponent } from './link-libros/link-libros.component';
import { MoocCrearComponent } from './mooc-crear/mooc-crear.component';
import { MoocGestionComponent } from './mooc-gestion/mooc-gestion.component';
import { MoocEvaluarComponent } from './mooc-evaluar/mooc-evaluar.component';

const routes: Routes = [
  { path: '', component: PagesComponent, children: [
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'usuarios-lt', component: UsuariosComponent, canActivate: [AuthGuard] },
    { path: 'usuarios-lt-detalle', component: UsuariosDetalleComponent, canActivate: [AuthGuard] },
    { path: 'usuarios-lt-detalle/:id', component: UsuariosDetalleComponent, canActivate: [AuthGuard] },
    { path: 'usuarios-lt-detalle-alumnos/:id', component: StudentsListComponent, canActivate: [AuthGuard] },
    { path: 'licencias-lt', component: LicenciasComponent, canActivate: [AuthGuard] },
    { path: 'juegos', component: JuegosComponent, canActivate: [AuthGuard] },
    { path: 'juego/nuevo', component: NuevoJuegoComponent, canActivate: [AuthGuard] },
    { path: 'juego/:slug', component: JuegoDetalleComponent, canActivate: [AuthGuard] },
    { path: 'progress', component: ProgressComponent },
    { path: 'lista-users', component: ListaUsersComponent, canActivate: [AuthGuard] },
    { path: 'new', component: UserCreateComponent, canActivate: [AuthGuard] }, 
    { path: 'update/:id', component: UserUpdateComponent, canActivate: [AuthGuard] },
    { path: 'abilities', component: AbilitiesComponent, canActivate: [AuthGuard] },
    { path: 'rutas', component: RutasDidacticasComponent, canActivate: [AuthGuard] },
    { path: 'lista-recursos', component: RecursosExternosComponent, canActivate: [AuthGuard] },
    { path: 'recurso/nuevo', component: CreateRecursoComponent, canActivate: [AuthGuard] },
    { path: 'update-recurso/:id', component: UpdateRecursoComponent, canActivate: [AuthGuard] },
    { path: 'user-license', component: LicenciasLtComponent, canActivate: [AuthGuard] },
    { path: 'users-au', component: UsersAuComponent, canActivate: [AuthGuard] },
    { path: 'au-detail/: id', component: AuDetailComponent, canActivate: [AuthGuard] },
    { path: 'au-detail-students/: id', component: AuStudentsComponent, canActivate: [AuthGuard] },
    { path: 'new-student/: id', component: AuAddStudentComponent, canActivate: [AuthGuard] },
    { path: 'tttka-license', component: LicenciasTTtekaComponent, canActivate: [AuthGuard] },
    { path: 'tttka-license-detail/:id', component: LicenciasTttekaDetalleComponent, canActivate: [AuthGuard] },
    { path: 'membresias', component: MembresiaComponent, canActivate: [AuthGuard] },
    { path: 'membresias-editar', component: MembresiaEditarComponent, canActivate: [AuthGuard] },
    { path: 'secciones', component: SeccionesLtComponent, canActivate: [AuthGuard] },
    { path: 'blogautor/:AoI', component: blogAutorComponent, canActivate: [AuthGuard] },
    { path: 'blogcreararticulo', component: blogCrearArticuloComponent, canActivate: [AuthGuard] },
    { path: 'blogeditararticulo', component: blogEditarArticuloComponent, canActivate: [AuthGuard] },
    { path: 'foro', component: foroComponent, canActivate: [AuthGuard] },
    { path: 'instructores/:AoI', component: blogAutorComponent, canActivate: [AuthGuard] },
    { path: 'crearwebinar', component: capacitacionCrearWebinar, canActivate: [AuthGuard] },
    { path: 'gestorwebinar', component: capacitacionGestorWebinar, canActivate: [AuthGuard] },
    { path: 'libro-nuevo', component: LibroNuevoComponent, canActivate: [AuthGuard] },
    { path: 'libros', component: LibrosComponent, canActivate: [AuthGuard] },
    { path: 'update-libro/:id', component: UpdateLibroComponent, canActivate: [AuthGuard] },
    { path: 'links-libros', component: LinkLibrosComponent, canActivate: [AuthGuard] },
    { path: 'crearmooc', component: MoocCrearComponent, canActivate: [AuthGuard] },
    { path: 'gestormoocs', component: MoocGestionComponent, canActivate: [AuthGuard] },
    { path: 'evaluar-mooc/:id', component: MoocEvaluarComponent, canActivate: [AuthGuard] },

    { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
