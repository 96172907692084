import { ContactForm } from './../../models/form-user';
import { ListaUsersService } from './../../services/lista-users.service';
import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
})
export class UserUpdateComponent implements OnInit {

  ContactModel = new ContactForm();
  private token: string = localStorage.getItem('token');
  constructor(private listausersService: ListaUsersService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.listausersService.verDetalleUsers(this.token, params.id).subscribe((response: any) => {
        this.ContactModel.id = response.id;
        this.ContactModel.email = response.email;
        this.ContactModel.first_name = response.first_name;
        this.ContactModel.last_name = response.last_name;
        this.ContactModel.role_id = response.role_id;
        this.ContactModel.status = response.status;
        this.ContactModel.deleted = response.deleted;
      }, error => {
        console.log(error);
      });
    });
  }

  ngOnInit() {
  }
  // Funcion que se activa cuando no se desea realizar ninguna accion 
  cancelar() {
    this.router.navigate(['lista-users']);
  }

  updateUser(form: NgForm) {
    console.log(form.value);
    let ContactModel = new ContactForm();
    ContactModel = form.value;
    if (form.value.status === '0') {
      ContactModel.deleted = 1;
    } else {
      ContactModel.deleted = 0;
    }
    ContactModel.peticion = '1';
    console.log(ContactModel);
    if (form.valid) {
        let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const  validate = regexp.test(form.value.email);
        if (validate !== false) {
          this.listausersService.updateUser(this.token, ContactModel, this.ContactModel.id).subscribe(
            (response: any) => {
              Swal.fire({
                type: 'success',
                title: 'Se actualizo el usuario',
                showConfirmButton: false,
                timer: 1500
              });
              this.router.navigate(['lista-users']);
            }, error => {
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al actualizar el usuario:'
              });
            }
          );
        } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Hay un error en tu email'
          });
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Hay un error en tu formulario'
        });
    }
  }
}
