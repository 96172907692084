import { Component, OnInit, Renderer2} from '@angular/core';
import { CapacitacionService } from '../../services/capacitacion.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { imagenesCapacitaciones } from '../../services/config';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-capacitacionGestorWebinar',
    templateUrl: './capacitacionGestorWebinar.component.html',
    styleUrls: ['./capacitacionGestorWebinar.component.css']
})

export class capacitacionGestorWebinar implements OnInit {

    public url = imagenesCapacitaciones.url;
    private webinarList:any;
    private tagsList: any;
    private temasList: any;
    private listAutores: any;
    private token: string = localStorage.getItem('token');

    private editAviable: boolean;
    private webinarSelec: any;
    private comentarios: any;
    private usuarios: any;
    private tagsArray: any;
    private autoresArray: any = [];
    private fecha: any = {};
    private today: any;
    private image: any;
    private webinar: any;
    private changeIMG: boolean = false;
    private validate: string = '';


    constructor(private capacitacionService: CapacitacionService, private renderer: Renderer2){
    } 

    selectImage(e:any){
        this.image = e.target.files;
    }

    async editWebinar(){
        this.validate = '';
        if(this.webinarSelec.titulo == '')
            this.validate = this.validate + ' El titulo no puede ser vacio.';
        if(this.webinarSelec.id_tema == null)
            this.validate = this.validate + ' Selecciona algun tema.';
        if(this.tagsArray.length == 0 || this.tagsArray == null)
            this.validate = this.validate + ' Selecciona almenos un tag.';
        if(this.fecha.dia == null)
            this.validate = this.validate + ' Selecciona un fecha.';
        if(this.fecha.hora == null || this.fecha.hora > 24 || this.fecha.hora < 0)
            this.validate = this.validate + ' Selecciona una hora valida.';
        if(this.fecha.minutos == null || this.fecha.minutos > 59 || this.fecha.minutos < 0)
            this.validate = this.validate + ' Selecciona una hora valida.';
        if(this.webinarSelec.duracion == null || this.webinarSelec.duracion < 0)
            this.validate = this.validate + ' Ponga una duracion para el webinar en minutos.';
        if(this.webinarSelec.descripcion == '')
            this.validate = this.validate + ' Agregue un descripcion.';
        if(this.autoresArray.length == 0 || this.autoresArray == null)
            this.validate = this.validate + ' Selecciona almenos un instructor.';
        if(this.webinarSelec.precio == null || this.webinarSelec.precio < 0)
            this.validate = this.validate + ' Agregue un precio al Webinar.';
        if(this.webinarSelec.codigo == '')
            this.validate = this.validate + ' Agregue un código para el Webinar.';
        if(this.webinarSelec.password == '')
            this.validate = this.validate + ' Agregue un password para el Webinar.';
        if(this.changeIMG){
            if(this.image == null || this.image == undefined)
                this.validate = this.validate + ' Seleccion una imagen.';
        }

        if(this.validate == ''){ 
            let json ={
                'id': this.webinarSelec.id,
                'titulo': this.webinarSelec.titulo,
                'id_tema': this.webinarSelec.id_tema,
                'tags': this.tagsArray.toString(),
                'fecha': this.webinarSelec.fecha,
                'duracion': this.webinarSelec.duracion+" minutos",
                'descripcion': this.webinarSelec.descripcion,
                'autores': this.autoresArray.toString(),
                'certificado': Number(this.webinarSelec.certificado),
                'especial': Number(this.webinarSelec.especial),
                'cobrar': Number(this.webinarSelec.cobrar),
                'precio': this.webinarSelec.precio, 
                'link': this.webinarSelec.link,
                'codigo': this.webinarSelec.codigo,
                'password': this.webinarSelec.password,
                'status': this.webinarSelec.status
            };  console.log(json);     
            if(this.changeIMG){
                let fd = new FormData();
                fd.append('image', this.image[0], this.image[0].name);
                fd.append('id', this.webinarSelec.id);
                await this.capacitacionService.postEditIMG(fd,this.token).subscribe((response: any) => {
                    Swal.fire("",response.message,"success");
                });
            }
            await this.capacitacionService.putEditWebinar(json,this.token).subscribe((response: any) => {
                this.refresWebinarsList();
                this.refreshData();
                
                Swal.fire("",response.message,"success");
            });
        }
        else{
            Swal.fire("",this.validate,"warning");
        }
    }

    ChangeStatusComment(e:any, ID:number){
        let status = Number(e.checked).toString();
        let id = ID.toString();
        this.capacitacionService.postStatusComment({"visible":status,"id":id},this.token).subscribe((response: any) => {
            Swal.fire({
                position: 'top-end',
                title: 'Comentario Actualizado',
                showConfirmButton: false,
                timer: 1500,
                type: 'success'
            });
        });
    }

    async selectWebinar(id:number){
        this.refreshData();
        this.capacitacionService.getWebinar(id, this.token).subscribe((response: any) => {
            this.webinarSelec = response.webinar[0];
            this.webinarSelec.id = id;
            this.webinarSelec.fecha = this.webinarSelec.fecha.replace(' ','T').slice(0, 16);
            console.log(this.webinarSelec);
            this.comentarios = response.comentarios;
            this.usuarios = response.usuarios;
            this.editAviable = true;

            this.tagsArray = this.webinarSelec.tags.split(",");
            this.autoresArray = this.webinarSelec.autores.split(",");

            setTimeout(() => this.refreshAndSelectTags() , 300);
            
            let duracion = this.webinarSelec.duracion.split(" ");
            this.webinarSelec.duracion = duracion[0];
        }); 
    }

    refreshAndSelectTags(){
        for(let i=0; i<this.tagsList.length; i++){
            var element = document.getElementById('tag-'+this.tagsList[i].id);
            element.classList.remove("selected");
            element.classList.add("unselected");
        }
        for(let i=0; i<this.tagsList.length; i++){
            if(this.tagsArray.includes(this.tagsList[i].slug)){
                var element = document.getElementById('tag-'+this.tagsList[i].id);
                element.classList.remove("unselected");
                element.classList.add("selected");
            }
        }
    }

    SelectTags(e:any, tag:any){
        let clase = e.target.className;
        if(clase == "unselected"){
            this.renderer.removeClass(e.target, 'unselected');
            this.renderer.addClass(e.target, 'selected');
            if(!this.tagsArray.includes(tag))
                this.tagsArray = this.tagsArray.concat([tag]);
        }
        if(clase == "selected"){
            this.renderer.removeClass(e.target, 'selected');
            this.renderer.addClass(e.target, 'unselected');
            if(this.tagsArray.includes(tag))
                var rm = this.tagsArray.splice(this.tagsArray.indexOf(tag), 1);
        }
    }

    otroAutor(){
        this.autoresArray.push(0);
    }

    quitarAutor(index:number){
        var rm = this.autoresArray.splice(index, 1);
    }

    refresWebinarsList(){
        this.capacitacionService.getWebinarList(this.token).subscribe((response: any) => {
            this.webinarList = response.webinars;
            console.log(this.webinarList);
        });
    }

    refreshData(){
        this.validate = '';
        this.editAviable = false;
        this.changeIMG = false;
        this.webinarSelec = {};
        this.comentarios = {};
        this.usuarios = {};
        this.tagsArray = [];
        this.autoresArray = [];
        this.image = null;
        this.fecha = {
            dia : "",
            hora : "",
            minutos : ""
        }
    }

    ngOnInit() {
        this.today = (new Date()).toISOString().slice(0, 10)+"T13:00";
        this.refreshData();        
        this.refresWebinarsList();
        this.capacitacionService.getTags(this.token).subscribe((response: any) => {
            this.tagsList = response.tags;
            console.log(this.tagsList);
        });
        this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
            this.temasList = response.temas;
            console.log(this.temasList);
        });
        this.capacitacionService.getAutores().subscribe((response: any) => {
            this.listAutores = response;
            console.log(this.listAutores);
        });
    }

    subirWebinar(e:any){
        this.webinar = e.target.files;
    }
    subirVideoWebinar(){ 
        Swal.showLoading();
        let fd = new FormData();
        fd.append('file', this.webinar[0], this.webinar[0].name);
        fd.append('id', this.webinarSelec.id);
        this.capacitacionService.postEnviarVideo(fd,this.token).subscribe((response: any) => {
            this.webinarSelec.link = response.name;
            Swal.fire("",response.message,"success");
        });
    }
}