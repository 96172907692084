import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { findUser } from 'src/app/services/findUser.service';
import { usersAU } from 'src/app/models/findUser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-au-detail',
  templateUrl: './au-detail.component.html',
  styleUrls: ['./au-detail.component.css']
})
export class AuDetailComponent implements OnInit {
  dataU = new usersAU();
  list: any[] = [];
  datateacher: any;
  id: any;
  classrooms: any;
  tekaclassrooms: any;
  students: any;
  numstudents: any;
  constructor(private findService: findUser, private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params;
    });

  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    this.findService.detailAU(token, this.id[" id"]).subscribe(
      response => {
        this.datateacher = response;
        this.classrooms = response['classrooms'];
        this.tekaclassrooms = response['tekaclassrooms'];
        this.students = response['tekastudents'];
        this.numstudents =this.students.length;
        console.log(this.numstudents);
        console.log(this.tekaclassrooms);
        console.log(this.classrooms);
        console.log(this.datateacher);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }
  verAlumnos(id){
    this.router.navigate(['au-detail-students',id]);
  }

}
