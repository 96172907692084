import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/games.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute, Router } from '@angular/router';

// Models
import { GamePrograma } from '../../models/game';
import { TagsService } from 'src/app/services/tags.service';
@Component({
  selector: 'app-game-programas',
  templateUrl: './game-programas.component.html'
})
export class GameProgramasComponent implements OnInit {
  @Input() gameId: number;
  @Input() update: number;
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  public gameProg = new GamePrograma();
  public Editor = ClassicEditor;
  private up = '0';
  private token = localStorage.getItem('token');
  constructor(private gameService: GameService,
              private activatedRoute: ActivatedRoute,
              private tagsService: TagsService) {
    this.activatedRoute.params.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
          this.gameProg.game_id = response.game.id;
          this.gameId = response.game.id;
          if (response.programas === null) {
            this.gameProg.programas = {
              sep: '',
              BI: '',
              CCSS: '',
              tags: ''
            };
          } else {
            this.gameProg.programas = {
              sep: response.programas.sep,
              BI: response.programas.BI,
              CCSS: response.programas.CCSS,
              tags: response.programas.tags
            };
            this.up = '1';
            const regex = /\s*,\s*/;
            this.tags = this.gameProg.programas.tags.split(regex);
            this.tags = this.tags.filter((element) => {
              return element !== '';
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });

    this.tagsService.getTags().subscribe(
      response => {
        this.tagsDB = Object.values(response);
        let auxTag = [];
        for (const tag of this.tagsDB) {
          auxTag.push(tag.nombre);
        }
        this.tagsDB = auxTag;
      }, error => {
        console.log(error.message);
      }
    );
  }

  ngOnInit() {
  }

  addPrograma(form: NgForm) {
    if (form.valid) {
      let gameData = new GamePrograma();
      if (this.gameId !== 0 && this.gameId !== undefined) {
        gameData.game_id = this.gameId;
        if (this.update === 1 && this.up === '1') {
          gameData.peticion = '1';
        }
        const programa = {
          sep: form.value.sep,
          BI: form.value.BI,
          CCSS: form.value.CCSS,
          tags: form.value.tags
        };
        gameData.programas = programa;
        this.gameService.addProgramas(this.token, gameData).subscribe(
          response => {
            Swal.fire({
              type: 'success',
              title: 'Se creo el programa academico',
              showConfirmButton: false,
              timer: 1500
            });
          }, error => {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear el programa:' + error.error.message
            });
          }
        );
      } else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Escoge un juego para crear la ficha'
        });
      }
    } else {
      console.log(form);
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Hay un error en tu formulario'
      });
    }
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd = this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        const caracter = this.gameProg.programas.tags[this.gameProg.programas.tags.length - 1];
        console.log(caracter);
        this.gameProg.programas.tags = this.gameProg.programas.tags + `, ${arrTags[arrTags.length - 2].trim()}`;
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.gameProg.programas.tags = '';
      if (this.tags.length > 0) {
        this.gameProg.programas.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.gameProg.programas.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.gameProg.programas.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.gameProg.programas.tags = '';
      if (this.tagsNew.length > 0) {
        this.gameProg.programas.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.gameProg.programas.tags = `${this.tags.join(',')}`;
        } else {
          this.gameProg.programas.tags = '';
        }
      }
    }
  }

}
