import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  public tokenPage: Date;
  public tokenLogin: any;
  public tokenLoginAux: Date; 

  private rol = Number(localStorage.getItem("rol"));
  constructor(private router: Router) {
  }


  ngOnInit() {

    let fechaLogin = new Date(localStorage.getItem('fechaLogin'));

    let fechaEntrada = new Date();


    // console.log('FechaLogin', fechaLogin);
    // console.log('FechaEntrada', fechaEntrada);

    let resta = fechaEntrada.getTime() - fechaLogin.getTime();
    let dif = Math.round(resta / (1000 * 60 * 60 * 24));
    //console.log(dif);

    if (dif >= 1) {
      localStorage.removeItem('token');
      this.router.navigate(['login']);
    }

  }

  verJuegos() {
    this.router.navigate(['juegos']);
  }

  nuevoJuego() {
    this.router.navigate(['juego/nuevo']);
  }

  verAdministradores() {
    this.router.navigate(['lista-users']);
  }

  labtakUsers() {
    this.router.navigate(['usuarios-lt']);
  }
  mostrarRecursos() {
    this.router.navigate(['lista-recursos']);
  }

  crearRecurso() {
    this.router.navigate(['recurso/nuevo']);
  }
  salir() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
  licencias() {
    this.router.navigate(['user-license']);
  }
  licenciasTTtk() {
    this.router.navigate(['tttka-license']);
  }

  usersAU() {
    this.router.navigate(['users-au']);
  }
  mostrarSecciones(){
    this.router.navigate(['secciones']);
  }
  nuevaLicencia(){
    this.router.navigate(['membresias']);
  }
  editarLicencia(){
    this.router.navigate(['membresias-editar']);
  }

  gestionAutores() {
    this.router.navigate(['blogautor/A']);
  }

  crearArticulo(){
    this.router.navigate(['blogcreararticulo']);
  }

  editarArticulo(){
    this.router.navigate(['blogeditararticulo']);
  }

  gestionForo(){
    this.router.navigate(['foro']);
  }

  gestionInstructores() {
    this.router.navigate(['instructores/I']);
  }

  crearWebinar() {
    this.router.navigate(['crearwebinar']);
  }

  gestorWebinar() {
    this.router.navigate(['gestorwebinar']);
  }
  
  crearMooc() {
    this.router.navigate(['crearmooc']);
  }

  gestorMoocs() {
    this.router.navigate(['gestormoocs']);
  }

  mostrarLibros(){
    this.router.navigate(['libros']);
  }

  crearLibro(){
    this.router.navigate(['libro-nuevo']);
  }

  linksLibro(){
    this.router.navigate(['links-libros']);
  }
}
