import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from './config';
import { map } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class TagsService {
    public url = config.url;
     constructor(public http: HttpClient){
     }

     getTags() {
         let headers: HttpHeaders = new HttpHeaders();
         headers = headers.append('Content-Type', 'application/json');
         // Petición
         return this.http.get(this.url + 'ts/tags/', { headers: headers });
     }
}
