export class Game {
    public id: number;
    public title: string;
    public descripcion?: string;
    public imagename?: string;
    public slug?: string;
    public status?: boolean;
}

export class GameDescription {
    public game_id: number;
    public descripcion_pedagogica = '';
    public descripcion_ludica = '';
    public asignatura = '';
    public nivel = '';
    public conceptos = '';
    public tags = '';
    public peticion = '0';
}

export class GamePrograma {
    public game_id: number;
    public programas = {
        sep: '',
        BI: '',
        CCSS: '',
        tags: ''
    };
    public peticion = '0';
}

export class GameRecurso {
    public game_id: number;
    public khan_academy = '';
    public sitios = '';
    public videos = '';
    public otras_actividades = '';
    public tags = '';
    public peticion = '0';
}
export class GameAbility {
    public game_id: number;
    public peticion = '0';
    public modelos = {
        autoconocimiento: {
            conciencia: 0,
            autoestima: 0,
            aprecio: 0,
            bienestar: 0
        },
        autorregulacion: {
            meta: 0,
            expresion: 0,
            regulacion: 0,
            generacion: 0,
            perseverancia: 0
        },
        autonomia: {
            iniciativa: 0,
            soluciones: 0,
            eficacia: 0
        },
        empatia: {
            bienestar: 0,
            perspectiva: 0,
            prejuicios: 0,
            sensibilidad: 0,
            cuidado: 0
        },
        colaboracion: {
            comunidad: 0,
            responsabilidad: 0,
            inclusion: 0,
            resolucion: 0,
            interdependencia: 0
         },
        ramas : {
            colaboracion: 0,
            empatia: 0,
            autonomia: 0,
            autorregulacion: 0,
            autoconocimiento: 0
        },
        modelo: 0
    };
    public habilidades = {
        aprendizaje: {
            pensamiento: 0,
            comunicacion: 0,
            creatividad: 0,
            colaboracion: 0
        },
        imt: {
            teclado: {
                nombre: '',
                porcentaje: 0
            },
            mouse: {
                nombre: '',
                porcentaje: 0
            },
            tableta: {
                nombre: '',
                porcentaje: 0
            }
        },
        fms: {
            atencion: 0,
            memoria: 0,
            lenguaje: 0,
            visoespacial: 0,
            sensoriomotoras: 0,
            ejecutivas: 0
        },
        ed: {
            visual: 0,
            trabajo: 0,
            informacion: 0,
            verbal: 0,
            auditiva: 0
        },
        tags: ''
    };
}

export class GameRuta{
    public game_id: number;
    public ruta_id:number;
    public peticion = '0';
    
        public ruta = {
        titulo: '',
        subtitulo: '',
        argumentacion: ''
    };
    public procesos = '';
    public tags = '';
}
