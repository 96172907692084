import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { findUser } from '../../services/findUser.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.css']
})
export class StudentsListComponent implements OnInit {

  public token = localStorage.getItem('token');
  public idUser: string;
  public lista_alumnos: any[] = [];

  constructor(private route: ActivatedRoute, private findService: findUser) {
    this.idUser = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {  
    this.findService.getPersonalUser(this.token, this.idUser).subscribe(
      response => {        
        this.lista_alumnos = response['lista_alumnos'];
        console.log(this.lista_alumnos);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

}
