export class Planeaciones {
    public id: number;
    public tipo_planeacion: string;
    public status: number;
}
export class PlaneacionesSes {
    public id: number;
    public tipo_planeaciones_ses: string;
    public status: number;
}
export class Foro {
    public id: number;
    public tipo_foro: string;
    public status: number;
}
export class Moocs {
    public id: number;
    public nombre: string;
    public status: number;
}

export class Webinars {
    public  id: number;
    public tipo_webinar: string;
    public status: number;
}