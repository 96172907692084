import { Component, OnInit, Renderer2} from '@angular/core';
import { ForoService } from '../../services/foro.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-foro',
  templateUrl: './foro.component.html',
  styleUrls: ['./foro.component.css']
})

export class foroComponent implements OnInit {

    private foro:any;
    private subtemas:any;
    private entradas:any;
    private tags:any; private tagsArray:any;
    private nombreInput:any; private visibleInput:boolean; private descripcionInput:any;
    private IDtemaInput:any; private IDsubtemaInput:any; 

    private entradaSelec = {};
    private subtemaSelec = {tags:""};
    private temaSelec = {};

    private editarTema:boolean;
    private editarSubtema:boolean;
    private editarEntrada:boolean;

    private crearTema:boolean;
    private crearSubtema:boolean;
    private crearEntrada:boolean;

    private btnCrearEntrada:boolean;
    private btnCrearSub:boolean;

    private IDemail = localStorage.getItem('email');

    constructor(private foroService: ForoService, private renderer: Renderer2){
    }

    uploadEntrada(){
      if(this.nombreInput != "" && this.descripcionInput!= ""){
        let d = {
          "IDsubtema":this.IDsubtemaInput,
          "IDusuario": this.IDemail,
          "nombre":this.nombreInput,
          "fecha":new Date().toISOString(),
          "visible":Number(this.visibleInput).toString(),
          "texto":this.descripcionInput,
        }
        this.foroService.postEntrada(d).subscribe((response: any) => {
          Swal.fire({
            position: 'top-end',
            title: 'Entrada Creada',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
          this.refresh();
        });
      }else{
        Swal.fire("","Llenar todos los campos","warning");
      }
    }

    uploadSubtema(){
      if(this.nombreInput != "" && this.descripcionInput!= "" && this.tagsArray.toString() != ""){
        let d = {
          "IDtema":this.IDtemaInput,
          "nombre":this.nombreInput,
          "visible":Number(this.visibleInput).toString(),
          "descripcion":this.descripcionInput,
          "tags":this.tagsArray.toString()
        }
        this.foroService.postSubtema(d).subscribe((response: any) => {
          Swal.fire({
            position: 'top-end',
            title: 'Subtema Creado',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
          this.refresh();
        });
      }else{
        Swal.fire("","Llenar todos los campos","warning");
      }
    }

    uploadTema(){
      if(this.nombreInput!=""){
        let d = {
          "nombre":this.nombreInput,
          "visible":Number(this.visibleInput).toString()
        }
        this.foroService.postTema(d).subscribe((response: any) => {
          Swal.fire({
            position: 'top-end',
            title: 'Tema Creado',
            showConfirmButton: false,
            timer: 1500,
            type: 'success'
          });
          this.refresh();
        });  
      }else{
        Swal.fire("","Llenar todos los campos","warning");
      }
    }

    crearNewEntrada(){
      this.editarEntrada=false;
      this.editarSubtema=false; 
      this.editarTema=false;
      this.crearEntrada=true;
      this.crearSubtema=false;
      this.crearTema=false;
      
      this.nombreInput="";
      this.visibleInput=true;
      this.descripcionInput="";
      this.tagsArray=[];
    }

    crearNewSubtema(){
      this.editarEntrada=false;
      this.editarSubtema=false; 
      this.editarTema=false;
      this.crearEntrada=false;
      this.crearSubtema=true;
      this.crearTema=false;
      
      this.nombreInput="";
      this.visibleInput=true;
      this.descripcionInput="";
      this.tagsArray=[];

    }

    crearNewTema(){
      this.editarEntrada=false;
      this.editarSubtema=false; 
      this.editarTema=false;
      this.crearEntrada=false;
      this.crearSubtema=false;
      this.crearTema=true;

      this.nombreInput="";
      this.visibleInput=true;
      this.descripcionInput="";
      this.tagsArray=[];
    }

    updateTema(){
      this.foroService.postUpdateTema(this.temaSelec).subscribe((response: any) => {
        Swal.fire({
          position: 'top-end',
          title: 'Tema Actualizado',
          showConfirmButton: false,
          timer: 1500,
          type: 'success'
        });
        this.refresh();
      });
    }  
    
    updateSubtema(){
      this.subtemaSelec.tags = this.tagsArray.toString();
      this.foroService.postUpdateSubtema(this.subtemaSelec).subscribe((response: any) => {
        Swal.fire({
          position: 'top-end',
          title: 'Subtema Actualizado',
          showConfirmButton: false,
          timer: 1500,
          type: 'success'
        });
        this.refresh();
      });
    }

    updateEntrada(){
      this.foroService.postUpdateEntrada(this.entradaSelec).subscribe((response: any) => {
        Swal.fire({
          position: 'top-end',
          title: 'Entrada Actualizada',
          showConfirmButton: false,
          timer: 1500,
          type: 'success'
        });
        this.refresh();
      }); 
    }

    updateComment(e:any, ID:number){
      let status = Number(e.checked).toString();
      let id = ID.toString();
      this.foroService.postUpdateComment({"visible":status,"ID":id}).subscribe((response: any) => {
        Swal.fire({
          position: 'top-end',
          title: 'Comentario Actualizado',
          showConfirmButton: false,
          timer: 1500,
          type: 'success'
        });
      }); 
    }

    editarTemaSelec(t:number){
        Object.assign(this.temaSelec, this.foro[t]);

        this.editarEntrada=false;
        this.editarSubtema=false; 
        this.editarTema=true;
        this.crearEntrada=false;
        this.crearSubtema=false;
        this.crearTema=false;
    }

    editarSubtemaSelec(s:number){
        Object.assign(this.subtemaSelec, this.subtemas[s]);
        this.tagsArray = this.subtemaSelec.tags.split(",");
        setTimeout(() => this.refreshAndSelectTags() , 400);

        this.editarEntrada=false;
        this.editarSubtema=true;
        this.editarTema=false;
        this.crearEntrada=false;
        this.crearSubtema=false;
        this.crearTema=false;
    }

    selectEntrada(e:number){
        Object.assign(this.entradaSelec, this.entradas[e]);

        this.editarEntrada=true;
        this.editarSubtema=false;
        this.editarTema=false;
        this.crearEntrada=false;
        this.crearSubtema=false;
        this.crearTema=false;
    }

    selectSubtema(s:number){
        this.entradas = this.subtemas[s].entrada;
        this.IDsubtemaInput = this.subtemas[s].ID;

        this.entradaSelec = {};

        this.btnCrearEntrada=true;
        this.editarEntrada=false;
        this.editarSubtema=false;
        this.editarTema=false;
        this.crearEntrada=false;
        this.crearSubtema=false;
        this.crearTema=false;
    }

    selectTema(t:number){
        this.subtemas = this.foro[t].subtema;
        this.IDtemaInput = this.foro[t].ID;

        this.entradas=null;
        this.entradaSelec = {};

        this.btnCrearSub=true;
        this.btnCrearEntrada=false;
        this.editarEntrada=false;
        this.editarSubtema=false;
        this.editarTema=false;
        this.crearEntrada=false;
        this.crearSubtema=false;
        this.crearTema=false;
    }

    SelectTags(e:any, tag:any){
      let clase = e.target.className;
      if(clase == "unselected"){
        this.renderer.removeClass(e.target, 'unselected');
        this.renderer.addClass(e.target, 'selected');
        if(!this.tagsArray.includes(tag))
            this.tagsArray = this.tagsArray.concat([tag]);
      }
      if(clase == "selected"){
        this.renderer.removeClass(e.target, 'selected');
        this.renderer.addClass(e.target, 'unselected');
        if(this.tagsArray.includes(tag))
            var rm = this.tagsArray.splice(this.tagsArray.indexOf(tag), 1);
      }
    }

    refreshAndSelectTags(){
      for(let i=0; i<this.tags.length; i++){
        var element = document.getElementById(this.tags[i].tag);
        element.classList.remove("selected");
        element.classList.add("unselected");
      }
      for(let i=0; i<this.tags.length; i++){
        if(this.tagsArray.includes(this.tags[i].tag)){
          var element = document.getElementById(this.tags[i].tag);
          element.classList.remove("unselected");
          element.classList.add("selected");
        }
      }
    }

    refresh(){
      this.foroService.getForoAdmin().subscribe((response: any) => {
        this.foro = response.foro;
        this.tags = response.tags;
      });
      this.editarEntrada=false;
      this.editarSubtema=false;
      this.editarTema=false;

      this.crearEntrada=false;
      this.crearSubtema=false;
      this.crearTema=false;

      this.btnCrearSub=false;
      this.btnCrearEntrada=false;

      this.subtemas=null;
      this.entradas=null;
    }

    ngOnInit() {
      this.refresh();
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: '200px',
          minHeight: '0',
          maxHeight: 'auto', 
          width: '800px',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Ingresa el contenido de la entrada aqui...',
          defaultParagraphSeparator: '',
          defaultFontName: 'Roboto',
          defaultFontSize: '3',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
          customClasses: [
          {
            name: 'quote',
            class: 'quote', 
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
          },
        ],
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
          [
            'strikeThrough',
            'subscript',
            'superscript',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'insertUnorderedList',
            'insertOrderedList',
            'heading',
            'fontName'
          ],
          [
            'fontSize',
            'textColor',
            'backgroundColor',
            'customClasses',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
            'removeFormat',
            'toggleEditorMode'
          ]
        ]
    };
}
