export var config = {
    //url: 'http://api.local.labtak:88/v1/'
    //url: 'http://api.takflics.com/v1/'
    url: 'https://api.dev.labtak.mx/v1/' 
    //url: 'http://localhost:4000/v1/' //pruebas Blog 
}

export var imagenesBlog ={ //pruebas de blog
    //url: 'http://localhost:4000/static/blog/'
    url: 'https://api.dev.labtak.mx/static/blog/' 
}

export var imagenesCapacitaciones ={ //pruebas de capacitaciones
    //url: 'http://localhost:4000/static/capacitaciones/'
    url: 'https://api.dev.labtak.mx/static/capacitaciones/'
}