import { Component, OnInit, Renderer2} from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { config } from '../../services/config';

import Swal from 'sweetalert2';

@Component({
    selector: 'app-blogCrearArticulo',
    templateUrl: './blogCrearArticulo.component.html',
    styleUrls: ['./blogCrearArticulo.component.css']
})

export class blogCrearArticuloComponent implements OnInit {
    private listAutores: any;
    private listTags: any;
    private listCategories: any;
    
    public url = config.url;

    //Para la Alta
    private visible: boolean;
    private destacado: boolean;
    private autor: any;
    private categoria: any;
    private tags: any = [];
    private titulo: string;
    private fecha: string;
    private resumen: string;
    private imagen: Array <File>;
    private articulo: any;

    private flag: boolean = false;

    constructor(private blogService: BlogService, private renderer: Renderer2){
    } 

    hola(){
        let s = this.visible.toString();
        let n = Number(this.visible).toString();
        console.log("string:",s,"boolean:",this.visible,"number:",n);
    }

    selectImage(e:any){
        this.imagen = e.target.files;
    }

    uploadArticle(){
        if(this.imagen != undefined)
        {
            if(this.imagen[0].type == "image/jpeg" || this.imagen[0].type == "image/png"){
                this.flag = true;
                // if(this.imagen[0].size > 51000 ){
                //     this.flag = false;
                //     Swal.fire("","La imagen debe pesar menos de 50KB","warning");
                // }
                // else{
                //     this.flag = true;
                // }
            }
            else{
                this.flag = false;
                Swal.fire("","Debe seleccionar una imagen jpg o png","warning");                
            }
        }
        else{
            this.flag = false;
            Swal.fire("","Debe seleccionar una imagen principal","warning");
        }
        let tagsStr = this.tags.toString();
        this.fecha = new Date().toISOString();
        if(
            this.autor != undefined && this.categoria != undefined && 
            !(this.articulo == undefined || this.articulo == "") && 
            !(this.titulo == undefined || this.titulo == "") && 
            !(this.resumen == undefined || this.resumen == "") &&
            this.imagen != undefined && this.flag && tagsStr != ""
        ){
            let fd = new FormData();
            fd.append('image', this.imagen[0], this.imagen[0].name);
            fd.append('visible', Number(this.visible).toString());
            fd.append('destacado', Number(this.destacado).toString());
            fd.append('IDautor', this.autor.toString());
            fd.append('IDcategoria', this.categoria.toString());
            fd.append('articulo', this.articulo);
            fd.append('titulo', this.titulo);
            fd.append('resumen', this.resumen);
            fd.append('fecha',this.fecha);
            fd.append('tags', tagsStr);
            this.blogService.postArticulo(fd).subscribe((response: any) => {
                Swal.fire("","Articulo Registrado","success");
                this.imagen = undefined;
                this.autor = undefined;
                this.categoria = undefined;
                this.articulo = undefined;
                this.titulo = undefined;
                this.resumen = undefined;
                this.fecha = "";
                this.tags = [];
            });
        }
        else{
          if(this.flag) Swal.fire("","Favor de llenar todos los campos","warning");
        }
    }

    SelectTags(e:any, tag:any){
        let clase = e.target.className;
        if(clase == "unselected"){
            this.renderer.removeClass(e.target, 'unselected');
            this.renderer.addClass(e.target, 'selected');
            if(!this.tags.includes(tag))
                this.tags = this.tags.concat([tag]);
        }
        if(clase == "selected"){
            this.renderer.removeClass(e.target, 'selected');
            this.renderer.addClass(e.target, 'unselected');
            if(this.tags.includes(tag))
                var rm = this.tags.splice(this.tags.indexOf(tag), 1);
        }
    }

    async crearCategoria(){
        let newCategoria:string;
        await Swal.fire({
            input: 'textarea',
            inputPlaceholder: 'Escriba aqui la categoria',
            showCancelButton: true
          }).then((text) => {
            if (text.value) {
              newCategoria = text.value;
            } else{
              newCategoria = "#no#";
            }
        })
        if(newCategoria != "#no#"){
            this.blogService.postCategoria(newCategoria).subscribe((response: any) => {
                this.refreshCategories();
                Swal.fire("","Categoria Registrada","success");
                this.categoria = "";
            });
        }
    }

    refreshCategories(){
        this.blogService.getCategories().subscribe((response: any) => {
            this.listCategories = response;
        });
    }
    
    async crearTag(){
        let newtag:string;
        await Swal.fire({
            input: 'textarea',
            inputPlaceholder: 'Escriba aqui el Tag',
            showCancelButton: true
          }).then((text) => {
            if (text.value) {
              newtag = text.value;
            } else{
              newtag = "#no#";
            }
        })
        if(newtag != "#no#"){
            this.blogService.postTag(newtag).subscribe((response: any) => {
                this.refreshTags();
                Swal.fire("","Tag Registrado","success");
                this.tags = [];
            });
        }
    }

    refreshTags(){
        this.blogService.getTags().subscribe((response: any) => {
            this.listTags = response;
        });
    }

    ngOnInit() {
        this.blogService.getAutores().subscribe((response: any) => {
            this.listAutores = response;
        });
        this.refreshTags();
        this.refreshCategories();
        this.visible = true;
        this.destacado = false;
        this.flag = false;
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: '25rem',
          minHeight: '5rem',
          maxHeight: 'auto',
          width: '700px',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Escriba el articulo aqui...',
          defaultParagraphSeparator: '',
          defaultFontName: 'Calibri',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
        uploadUrl: this.url + 'lt-blog/imageblog',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [ ['fontSize'] ]
    };
}