import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class ForoService{

    public url = config.url;
    constructor(public http: HttpClient) { }

    getForoAdmin(): Observable<any>{
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        //headers = headers.append('Authorization', `Bearer ${token}`);
        
        return this.http.get(this.url+'lt-foro/foroAdmin', { headers: headers });
    }

    postUpdateTema(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postUpdateTema', body, { headers: headers });
    }

    postUpdateSubtema(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postUpdateSubtema', body, { headers: headers });
    }

    postUpdateEntrada(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postUpdateEntrada', body, { headers: headers });
    }

    postUpdateComment(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postUpdateComment', body, { headers: headers });
    }

    postTema(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postTema', body, { headers: headers });
    }

    postSubtema(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postSubtema', body, { headers: headers });
    }

    postEntrada(body:any): Observable<any>{
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      //headers = headers.append('Authorization', `Bearer ${token}`);
      
      return this.http.post(this.url+'lt-foro/postEntrada', body, { headers: headers });
    }
}  