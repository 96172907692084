import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitacion, DatosUsuario, Facturacion, Licencia, SchoolCCT, Secciones } from 'src/app/models/licencias';
import { AdquisicionService } from 'src/app/services/adquisicion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-membresia',
  templateUrl: './membresia.component.html',
  styleUrls: ['./membresia.component.css']
})
export class MembresiaComponent implements OnInit {

  token: string = localStorage.getItem('token');
  id_user: string = localStorage.getItem('us');

  public section = 1;
  public addcct = 0;
  public licencia = new Licencia();
  public usuario = new DatosUsuario();
  public factura = new Facturacion();
  public capacitacion = new Capacitacion();
  public secciones = new Secciones();
  public school = new SchoolCCT();
  
  Arr = Array;
  public numero_cap = 0;
  public fechas_capacitaciones = [];
  public id_licencia ;
  public correos;
  public estados = [];
  public municipios = [];
  public ccts = [];
  public sourceexist = 0;
  public sources = [];
  
  fileToUpload: File = null;

  public fecha =new Date().toISOString().slice(0,10); 

  secciones_dis: Array<any> = [
    { nombre: 'Fichas de videojuegos TAKTAKTAK', id: 1, value: false, tipo: 1 },
    { nombre: 'Buscador de recursos', id: 1, value: false, tipo: 2 },
    { nombre: 'Módulos Labtak', id: 1, value: false, tipo: 3 },
    { nombre: 'ReporTAK', id: 1, value: false, tipo: 4 },
    { nombre: 'Grupos', id: 1, value: false, tipo: 5 },
    { nombre: 'Blog', id: 1, value: false, tipo: 6 },
  ];

  constructor(private adquisicionService: AdquisicionService, private router: Router) { 
    this.adquisicionService.getSecciones(this.token).subscribe((response: any) => {
      response.secciones.seccion_planeaciones.forEach(p => {
        this.secciones_dis.push({nombre: p.tipo_planeacion, id: p.id, value: false, tipo: 7 }); 
      });
      response.secciones.seccion_planeaciones_ses.forEach(ps => { 
        this.secciones_dis.push({nombre: ps.tipo_planeaciones_ses, id: ps.id, value: false, tipo: 8 }); 
      });
      response.secciones.seccion_foro.forEach(f => {
        this.secciones_dis.push({nombre: f.tipo_foro, id: f.id, value: false, tipo: 9 });
      });
      response.secciones.seccion_moocs.forEach(m => {
        this.secciones_dis.push({nombre: m.nombre, id: m.id, value: false, tipo: 10 }); 
      });
      response.secciones.seccion_webinars.forEach(w => { 
        this.secciones_dis.push({nombre: w.tipo_webinar, id: w.id, value: false, tipo: 11 }); 
      });
    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit() {
  }

  activar(event: any, numero) { 
    if (event.target.checked) {
      (numero == 1) ? this.licencia.pagado = 1 : this.usuario.facturacion = 1;
    } else {
      (numero == 1) ? this.licencia.pagado = 0 : this.usuario.facturacion = 0;
    }
  }

  sesiones:any = (e) => {
    this.numero_cap = Number(this.capacitacion.numero);
  }

  asignaraSecciones() {
    this.secciones_dis.forEach(sec => {
      if(sec.tipo == 1) (sec.value) ? this.secciones.fichas = 1 : this.secciones.fichas = 0 ;
      else if(sec.tipo == 2) (sec.value) ? this.secciones.buscador = 1 : this.secciones.buscador = 0;
      else if(sec.tipo == 3) (sec.value) ? this.secciones.modulos_ttt = 1 : this.secciones.modulos_ttt = 0;
      else if(sec.tipo == 4) (sec.value) ? this.secciones.reportak = 1 : this.secciones.reportak = 0;
      else if(sec.tipo == 5) (sec.value) ? this.secciones.grupos = 1 : this.secciones.grupos = 0;
      else if(sec.tipo == 6) (sec.value) ? this.secciones.blog = 1 : this.secciones.blog = 0;
      else if(sec.tipo == 7) { 
        if(sec.value){
          (this.secciones.planeacion=='') ? this.secciones.planeacion = sec.id  : this.secciones.planeacion = this.secciones.planeacion + ',' + sec.id  ;
        }
      }
      else if(sec.tipo == 8) { 
        if(sec.value){
          (this.secciones.planeaciones_ses=='') ? this.secciones.planeaciones_ses = sec.id  : this.secciones.planeaciones_ses = this.secciones.planeaciones_ses + ',' + sec.id;
        }
      }
      else if(sec.tipo == 9) { 
        if(sec.value){
          (this.secciones.foro=='') ? this.secciones.foro = sec.id  : this.secciones.foro =  this.secciones.foro + ',' + sec.id;
        }
      }
      else if(sec.tipo == 10) { 
        if(sec.value){
          (this.secciones.moocs=='') ? this.secciones.moocs = sec.id  : this.secciones.moocs =  this.secciones.moocs + ',' + sec.id;
        }
      }
      else if(sec.tipo == 11) { 
        if(sec.value){
          (this.secciones.webinars=='') ? this.secciones.webinars = sec.id  : this.secciones.webinars =  this.secciones.webinars  + ',' + sec.id;
        }
      }
    });
    if(this.secciones.planeacion=='') this.secciones.planeacion = '0';
    if(this.secciones.planeaciones_ses=='') this.secciones.planeaciones_ses = '0';
    if(this.secciones.foro=='') this.secciones.foro = '0';
    if(this.secciones.moocs=='') this.secciones.moocs = '0';
    if(this.secciones.webinars=='') this.secciones.webinars = '0';
  }

  guardar(form: NgForm) {
    Swal.showLoading();
    if(form.valid) {
      this.usuario.id_sys_user = Number(this.id_user);
      this.capacitacion.fechas = this.fechas_capacitaciones;
      if(this.licencia.id_secciones==0){ 
        this.asignaraSecciones();
        this.licencia.id_secciones=null; 
        this.licencia.secciones = this.secciones;
      }
      this.licencia.datos = this.usuario;
      if(this.usuario.facturacion==1) this.licencia.datos_facturacion = this.factura;
      if(this.licencia.pagado==0) this.licencia.status = 0;
      this.licencia.capacitacion = this.capacitacion;

      this.adquisicionService.crearLicencia(this.token, this.licencia).subscribe((response: any) => {
        
        Swal.close();
        console.log(response);
           this.id_licencia = response.id_license;
          Swal.fire({
            type: 'success',
            title: 'Licencia creada ',
            showConfirmButton: false,
            timer: 2000
          });
          this.section = 2;
        }, error => {
          
          console.log(error);
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error al crear la licencia:' + error.error.message
          });
        });
    }
    else{
      Swal.fire({
        type: 'error',
        title: '',
        text: 'No has llenado todos los campos.'
      });
    }
  }

  omitir(){
    this.router.navigate(['membresias-editar']);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  subirArchivo(){
    this.adquisicionService.uploadFile(this.fileToUpload, this.id_licencia).subscribe((response: any) => {
      console.log(response);
      Swal.fire({
          type: 'success',
          title: 'Invitaciones enviadas.', 
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['membresias-editar']);
      }, error => {
        
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al enviar las invitaciones'
        });
      });
  }

  guardarCorreos(){
    console.log(this.correos);
    let correosenviar = this.correos.split(',');
    console.log(correosenviar);
    this.adquisicionService.enviarInvitaciones(this.token, {emails: correosenviar, id_license: this.id_licencia }).subscribe((response: any) => {
      
      console.log(response);
        Swal.fire({
          type: 'success',
          title: 'Invitaciones enviadas.', 
          showConfirmButton: false,
          timer: 2000
        });
        this.router.navigate(['membresias-editar']);
      }, error => {
        
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al enviar las invitaciones'
        });
      });
  }

  addccts(){
    this.addcct = 1;
    this.adquisicionService.catState(this.token).subscribe((response: any) => {
      this.estados = response;
    });
  }

  handleMunicipio:any = (e) => {
    this.adquisicionService.catMunicipio(this.token, this.school.estado).subscribe((response: any) => {
      this.municipios = response;
    });
  }

  handleCCT:any = (e) => {
    this.adquisicionService.catCCTState(this.token, this.school).subscribe((response: any) => {
      this.ccts = response;
      console.log(this.ccts);
    });
  }

  pedirSources(){
    this.sourceexist = 1;
    if(this.sources.length==0){
      this.adquisicionService.getSource(this.token).subscribe((response: any) => {
        this.sources = response.sources;
      });
    }
  }

}
