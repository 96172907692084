import { Component, OnInit, Input } from '@angular/core';
import { GameService } from '../../services/games.service';
import { Game } from '../../models/game';
@Component({
  selector: 'app-form-game',
  templateUrl: './form-game.component.html',
  styles: []
})
export class FormGameComponent implements OnInit {
  public gameId: number;
  public option = '0';
  public inomaGames: Game[]  = [];
  public desc = 0;
  public add = 0;
  public add2 = 0;
  constructor(private gameService: GameService) {
    this.gameService.inomaGames().subscribe((response: any) => {
      this.inomaGames = response;
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {
  }

  capturar() {
    this.gameId = Number(this.option);
  }

  mostrarOtros() {
    this.desc = 1;
  }
  agregarRuta() {
    this.add = 1;
  }

  agregarRuta1() {
    this.add2 = 1;
  }


}
