import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LibrosTexto, Links, OtroRecurso } from '../models/otros-recursos';
import { Observable } from 'rxjs';
import { config } from './config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OtrosRecursosService {

  public url = config.url;

  constructor(public http: HttpClient) { }

  // Muestra los recursos externos  que se encuentran en la base de datos
  mostrarRecursos(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/recursos-externos', { headers: headers });
  }
  verDetalle(token: string, id) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    return this.http.get(this.url + 'ts/recursos-externos', { headers: headers })
      .pipe(map(data => {
        //console.log(data);
        return data;
      })
      );
  }
  addNewRecurso(token: string, data: OtroRecurso): Observable<any> {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    data.peticion = '0';
    const url = this.url + `ts/recursos-externos`;
    return this.http.post(url, data, { headers: headers });
  }
  actualizarRecurso(token: string, data: OtroRecurso, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    data.peticion = '1';
    let url = this.url + `ts/recursos-externos/${id}`;
    return this.http.post(url, data, { headers: headers });
  }
  eliminarRecurso(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/recursos-externos/${id}`;
    return this.http.delete(url, { headers: headers });
  }
  verDetalleRecurso(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/recursos-externos/${id}`;
    return this.http.get(url, { headers: headers });
  }

  obtenerPDF(token: string, id_ruta: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/getPDF/${id_ruta}`;
    return this.http.get(url, { headers: headers });
  }

  links(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/links-libros', { headers: headers });
  }

  mostrarLibros(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'ts/libros-texto', { headers: headers });
  }

  consultarLibro(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    let url = this.url + `ts/libros-texto/${id}`;
    return this.http.get(url, { headers: headers });
  }

  eliminarLibro(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `ts/libros-texto/${id}`;
    return this.http.delete(url, { headers: headers });
  }

  actualizarLibro(token: string, data: LibrosTexto, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    let url = this.url + `ts/libros-texto/${id}`;
    return this.http.post(url, data, { headers: headers });
  }

  nuevoLibro(token: string, data: LibrosTexto) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    let url = this.url + `ts/libros-texto`;
    return this.http.post(url, data, { headers: headers });
  }

  consultarLink(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    let url = this.url + `ts/links-libros/${id}`;
    return this.http.get(url, { headers: headers });
  }

  actualizarLink(token: string, data: Links, id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    let url = this.url + `ts/links-libros/${id}`;
    return this.http.post(url, data, { headers: headers });
  }

}
