import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _userService: UserService, private router: Router) { }

  canActivate() {
    if( !this._userService.isLogged() ) {
      this.router.navigate(['/login']);
    }
    return true;
  }
  
}