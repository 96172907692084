import { Component, OnInit, ViewChild } from '@angular/core';
import { GameService } from '../../services/games.service';
import { GameDescription, GamePrograma, GameRecurso, Game } from '../../models/game';
import { ActivatedRoute, Router } from '@angular/router';
import { TagsService } from '../../services/tags.service';

@Component({
  selector: 'app-juego-detalle',
  templateUrl: './juego-detalle.component.html',
  styleUrls: ['./juego-detalle.component.css']
})
export class JuegoDetalleComponent implements OnInit {
  public game = new Game();
  public isFull = false;
  public update = 1;
  public add = 0;
  public add2 = 0;
  public tags: string[] = [];
  private token: string = localStorage.getItem('token');
  public rutasTotales: any[];
  constructor(private gameService: GameService, private activatedRoute: ActivatedRoute, private router: Router, private Tags: TagsService) {
    this.activatedRoute.params.subscribe(params => {
      this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
        this.game = response.game;
        this.isFull = true;
        this.rutasTotales = response.rutas;
      }, error => {
        console.log(error);
      });
    });
  }

  ngOnInit() {

  }

  mostrarTags() {
    this.Tags.getTags().subscribe(
      (response: any) => {
        this.tags = response;          
      }, error => {
        console.log(error);
      });
  }

  eliminarFicha() {
    this.gameService.eliminarFicha(this.token, this.game.slug).subscribe(
      (response: any) => {
        console.log('se borro ');
        this.router.navigate(['juegos']);
      }, error => {
        console.log(error);
      });
  }

  agregarRuta() {
    this.add = 1;
  }

  agregarRuta1() {
    this.add2 = 1;
  }

}
