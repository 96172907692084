import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService]

})
export class LoginComponent implements OnInit {

  public user: User;
  refresh: string = "false";
  mensaje? = "";
  isCorrect: boolean = true; 

  constructor(private userService: UserService, private router: Router) {
    localStorage.clear();
    this.user = new User(1, '', '');
  }


  ngOnInit() {
    localStorage.setItem('refresh', this.refresh);

  }

  onSubmit(form: any) {
    //console.log(this.user);
    this.userService.login(this.user).subscribe((response: any) => {
      
      if (localStorage.getItem('token')) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
      localStorage.setItem('token', response.token);
      let fechaLogin = new Date();
      let fechaAux = fechaLogin.toString();
      localStorage.setItem('us', response.id);
      localStorage.setItem('fechaLogin', fechaAux);
      localStorage.setItem('email', this.user.email);      
      localStorage.setItem('rol', response.rol_id);
      switch(response.rol_id){
        case 3: {this.router.navigate(['juegos']); break;}
        case 4: {this.router.navigate(['usuarios-lt']); break;}
        case 5: {this.router.navigate(['lista-recursos']); break;}
      }
      
    }, error => {
      this.router.navigate(['login']);    
      if(error.statusText === "Unauthorized"){
        this.mensaje = "Datos incorrectos";
        this.isCorrect = false;
        this.user.email = "";
        this.user.password = "";
      }
    });
  }

}
