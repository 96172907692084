import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Actividad, Materiales, Modulo, Mooc } from 'src/app/models/moocs';
import { CapacitacionService } from 'src/app/services/capacitacion.service';
import Swal from 'sweetalert2';
import { imagenesCapacitaciones } from '../../services/config';

@Component({
  selector: 'app-mooc-gestion',
  templateUrl: './mooc-gestion.component.html',
  styleUrls: ['./mooc-gestion.component.css']
})
export class MoocGestionComponent implements OnInit {

  private mooc = new Mooc();
  private token: string = localStorage.getItem('token');
  private moocsList = [];
  public url = imagenesCapacitaciones.url;
  private tagsList: any;
  private temasList: any;
  private listAutores: any;
  private section = 0;
  private editAviable: boolean;
  private tagsArray: any;
  private autoresArray: any = [];
  private image: any;
  private changeIMG: boolean = false;
  private usuarios: any;
  private id_mooc = 0;

  private modulos = [];

  constructor(private capacitacionService: CapacitacionService, private router: Router, private renderer: Renderer2){ }

  ngOnInit() {
    this.refreshData();
    this.refreshMoocsList();

    this.capacitacionService.getTags(this.token).subscribe((response: any) => {
      this.tagsList = response.tags;
    });
    this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
      this.temasList = response.temas;
    });
    this.capacitacionService.getAutores().subscribe((response: any) => {
      this.listAutores = response;
    });
  }

  refreshData(){
    this.editAviable = false;
    this.changeIMG = false;
    this.mooc = new Mooc();
    this.usuarios = [];
    this.tagsArray = [];
    this.autoresArray = [];
    this.image = null;
    this.id_mooc = 0;
  }

  refreshMoocsList(){
    this.capacitacionService.getMoocsList(this.token).subscribe((response: any) => {
      this.moocsList = response.moocs;
    });
  }

  selectMooc(id:number){
    Swal.showLoading();
    this.section = 1;
    this.id_mooc = id;
    this.capacitacionService.getMooc(id, this.token).subscribe((response: any) => {
      this.mooc = response.mooc;
      response.mooc.modulos.forEach(mo => {
        mo.actividades.forEach(act => {
          if(act.materiales)  act.materiales.forEach(mat => { mat.change = false; });
        });
      });
      this.modulos = response.mooc.modulos;
      this.mooc.fecha_inicio = this.mooc.fecha_inicio.replace(' ','T').slice(0, 16);
      this.tagsArray = this.mooc.tags.toString().split(",");
      this.autoresArray = this.mooc.autores.split(",");
      this.editAviable = true;
      this.usuarios = response.usuarios;
       setTimeout(() => this.refreshAndSelectTags() , 300);
       Swal.close();
    }); 
  }

  selectImage(e:any){
    this.image = e.target.files;
  }

  refreshAndSelectTags(){
    for(let i=0; i<this.tagsList.length; i++){
      var element = document.getElementById('tag-'+this.tagsList[i].id);
      element.classList.remove("selected");
      element.classList.add("unselected");
    }
    for(let i=0; i<this.tagsList.length; i++){
      if(this.tagsArray.includes(this.tagsList[i].slug)){
        var element = document.getElementById('tag-'+this.tagsList[i].id);
        element.classList.remove("unselected");
        element.classList.add("selected");
      }
    }
  }

  SelectTags(e:any, tag:any){
    let clase = e.target.className;
    if(clase == "unselected"){
      this.renderer.removeClass(e.target, 'unselected');
      this.renderer.addClass(e.target, 'selected');
      if(!this.tagsArray.includes(tag))
        this.tagsArray = this.tagsArray.concat([tag]);
    }
    if(clase == "selected"){
      this.renderer.removeClass(e.target, 'selected');
      this.renderer.addClass(e.target, 'unselected');
      if(this.tagsArray.includes(tag))
        var rm = this.tagsArray.splice(this.tagsArray.indexOf(tag), 1);
    }
  }

  otroAutor(){
    this.autoresArray.push(0);
  }

  quitarAutor(index:number){
    var rm = this.autoresArray.splice(index, 1);
  }

  cancelar() {
    this.refreshData()
    this.section = 0;
  }

  async crearTema(){
    let newCategoria:string;
    await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escriba aqui el tema',
      showCancelButton: true
    }).then((text) => {
        if (text.value) {
          newCategoria = text.value;
        } else{
          newCategoria = "#no#";
        }
      })
    if(newCategoria != "#no#"){
      let data= {'tema':newCategoria}; //console.log(data);
      this.capacitacionService.postTemas(data, this.token).subscribe((response: any) => {
        this.refreshTemas();                
        //console.log(response);
        Swal.fire("",response.message,"success");
        this.mooc.id_tema = "";
      });
    }
  }
  refreshTemas(){
    this.capacitacionService.getTemas(this.token).subscribe((response: any) => {
      this.temasList = response.temas;
    });
  }

  async crearTag(){
    let newtag:string;
    await Swal.fire({
      input: 'textarea',
      inputPlaceholder: 'Escriba aqui el Tag',
      showCancelButton: true
    }).then((text) => {
      if (text.value) { 
        newtag = text.value;
      } else{
        newtag = "#no#"; 
      }
     })
     if(newtag != "#no#"){
      let data= {'tag':newtag}; //console.log(data);
      this.capacitacionService.postTags(data, this.token).subscribe((response: any) => {
        this.refreshTags();
        //console.log(response);
        Swal.fire("",response.message,"success");
      });
     }
  }

  refreshTags(){
    this.capacitacionService.getTags(this.token).subscribe((response: any) => {
      this.tagsList = response.tags;
    });
  }

  async editarMooc(){
    Swal.showLoading();
    if(this.changeIMG){
      let fd = new FormData();
      fd.append('image', this.image[0], this.image[0].name);
      fd.append('id', this.id_mooc.toString());
      await this.capacitacionService.postEditIMGMooc(fd,this.token).subscribe((response: any) => {
        Swal.fire("",response.message,"success");
      });
    }
    await this.capacitacionService.putEditMooc(this.mooc, this.token, this.id_mooc, '1').subscribe((response: any) => {
      this.refreshMoocsList();
      this.cancelar();
      Swal.fire("",response.message,"success");
    });
  }

  addActividad(modulo){
    let acti = new Actividad();
    if(modulo.actividades==undefined) modulo.actividades = [];
    acti.actividad = modulo.actividades.length+1;
    acti.modulo = modulo.id;
    modulo.actividades.push(acti);
  }

  addMaterial(modulo, index){
    if (modulo.actividades[index].materiales==undefined) modulo.actividades[index].materiales = [];
    let materr = new Materiales();
    materr.change = true;
    modulo.actividades[index].materiales.push(materr);
  }

  addModulo(){
    let mod = new Modulo();
    mod.id_mooc = this.id_mooc;
    mod.modulo = this.modulos.length+1;
    mod.deleted = false;
    this.modulos.push(mod);
  }

  uploadArchivo(e:any, modulo, acti, mat ){
    modulo.actividades[acti].materiales[mat].file = e.target.files;
  }

  eliminarModulo(modul,m){
    //Si no tiene id sacar solo del array, si tiene id agregarle el delete
    if(modul.id){
      Swal.fire({
        title: '¿Seguro quieres eliminar este módulo?',
        text: "Ya no podrás recuperarlo. Si solo deseas que el usuario no lo vea solo actualiza el status.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar'
      }).then((result) => {
        if (result.value) {
          modul.deleted = true;
          this.capacitacionService.putEditMooc(modul, this.token, this.id_mooc, '3').subscribe((response: any) => {  
            Swal.fire("",response.message,"success");
          }, error => {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error'
            });
          });
        }
      })
    }else{
      this.modulos.splice(m,1);
    }
  }

  eliminarMaterial(modulo, act, index){
    modulo.actividades[act].materiales.splice(index,1);
  }
  eliminarActividad(modulo, act){
    modulo.actividades.splice(act,1);
  }

  actualizarModulo(modul) {
    let modulo = new Modulo();
    modulo = modul;

    Swal.showLoading();
    if(modulo.id && this.id_mooc!=0) { // si el modulo ya existe
      if(modulo.actividades){
        modulo.actividades.forEach((ma, aindex) => {
          if(!ma.actividad && ma.id) ma.actividad = aindex+1;
            //console.log(aindex, ma.actividad);
            if(ma.materiales){
              ma.materiales.forEach(mam => {
                if(ma.id) {
                  mam.id_actividad = ma.id;
                }
                mam.actividad = ma.actividad;
              });
            }
        });
      }
      
      this.capacitacionService.putEditMooc(modulo, this.token, this.id_mooc, '2').subscribe((response: any) => {
        let ids = response.ids_actividades;
        //Asignar el id de las actividades nuevas y enviar cada material.
        if(modulo.actividades){
          modulo.actividades.forEach(ma => {
            if(!ma.id) {
              ids.forEach(i => {
                if(ma.actividad == i.actividad) ma.id = i.id_actividad;
              });
            }
            if(ma.materiales){
              ma.materiales.forEach(mat => {
                if(!mat.id){
                  ids.forEach(i => {
                    if(mat.actividad == i.actividad){
                      mat.id_actividad = i.id_actividad;
                    }
                  });
                  if(mat.id_actividad){
                    let fd1 = new FormData();
                    fd1.append('file', mat.file[0], mat.file[0].name);
                    fd1.append('nombre', mat.nombre);
                    fd1.append('duracion', mat.duracion.toString());
                    fd1.append('actividad', mat.actividad.toString());
                    fd1.append('id_actividad', mat.id_actividad.toString());
                    fd1.append('orden', mat.orden.toString());
                    fd1.append('tipo', mat.tipo);
                    fd1.append('status', mat.status.toString());
                    //console.log(fd1);
                    this.capacitacionService.crearMaterial(this.token, fd1).subscribe((response: any) => {  
                      mat.change = false;
                      Swal.fire("",response.message,"success");
                    }, error => {
                      Swal.fire("",error.message,"error");
                    });
                  }
                }
                else if(mat.id && mat.change){
                  //editar archivo
                  let fd1 = new FormData();
                  fd1.append('file', mat.file[0], mat.file[0].name);
                  this.capacitacionService.putEditMaterial(fd1, this.token, mat.id).subscribe((response: any) => {
                      Swal.fire("",response.message,"success");
                      mat.archivo = response.archivo; 
                      mat.change = false;
                    }, error => {
                      Swal.fire("",error.message,"error");
                    });
                }
              });
            }
          });
        }
        Swal.fire("",response.message,"success");
        }, error => {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Ocurrio un error:' + error.error.message
          });
        });
    }
    else{
      // Si el modulo no existe.
      this.createModulo(modulo);
    }
  }

  createModulo(modulo){
    
    if(modulo.actividades){
      modulo.actividades.forEach(ma => {
        ma.modulo = modulo.modulo;
        if(ma.materiales){
          ma.materiales.forEach(mam => {
            mam.actividad = ma.actividad;
          });
        }
      });
    }

    this.capacitacionService.crearModulo(this.token, modulo).subscribe((response: any) => {
      modulo.id = response.id_modulo;
      let ids = response.ids_actividades;
      //Asignar el id de la actividad y enviar cada material.
      if(modulo.actividades){
        modulo.actividades.forEach(ma => {
          if(ma.materiales){
            ma.materiales.forEach(mat => {
              ids.forEach(i => {
                if(mat.actividad == i.actividad){
                  mat.id_actividad = i.id_actividad;
                }
              });
              if(mat.id_actividad){
                let fd1 = new FormData();
                fd1.append('file', mat.file[0], mat.file[0].name);
                fd1.append('nombre', mat.nombre);
                fd1.append('duracion', mat.duracion.toString());
                fd1.append('actividad', mat.actividad.toString());
                fd1.append('id_actividad', mat.id_actividad.toString());
                fd1.append('orden', mat.orden.toString());
                fd1.append('tipo', mat.tipo);
                fd1.append('status', mat.status.toString());
                //console.log(fd1);
                this.capacitacionService.crearMaterial(this.token, fd1).subscribe((response: any) => {  
                  Swal.fire("",response.message,"success");
                }, error => {
                  Swal.fire("",error.message,"error");
                });
              }
            });
          }
        });
      }
      Swal.fire("",response.message,"success");
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Ocurrio un error:' + error.error.message
      });
    });
  }

  evaluarMooc(id:number){
    this.router.navigate(['evaluar-mooc/', id]);
  }

}
