import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from './config';
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})

export class BlogService{

  public url = config.url;
  constructor(public http: HttpClient) { }

////////////////////////////////////////GESTOR AUTORES////////////////////////////////////////////////////
  getAutores(): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/autor', { headers: headers });
  }

  postAutor(data:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    //return this.http.post(this.url+'lt-blog/postAutor', data, { headers: headers });
    return this.http.post(this.url+'lt-blog/postAutor', data);
  }

  postStatusAutores(data:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/changeStatusAutor', data, { headers: headers });
  }

  postEditAutorNoPhoto(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postEditAutorNoPhoto', body, { headers: headers });
  }

  postEditAutorWPhoto(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postEditAutorWPhoto', body);
  }
////////////////////////////////////////END: GESTOR AUTORES/////////////////////////////////////////////

////////////////////////////////////////CREAR ARTICULO//////////////////////////////////////////////////
  getTags(): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/tags', { headers: headers });
  }

  getCategories(): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/categories', { headers: headers });
  }

  postCategoria(cat:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/postCategories/'+cat, { headers: headers });
  }

  postTag(tag:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/postTags/'+tag, { headers: headers });
  }

  postArticulo(data:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'multipart/form-data');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postArticulo', data);
  }
////////////////////////////////////////END: CREAR ARTICULO///////////////////////////////////////////////

////////////////////////////////////////EDITAR ARTICULO///////////////////////////////////////////////////
  getArticulos(): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/blogAdmin', { headers: headers });
  }

  getArticuloSelec(id:string): Observable<any>{

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.get(this.url+'lt-blog/getArticuloAdmin/'+id, { headers: headers });
  }

  postUpdateComment(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postUpdateComment', body, { headers: headers });
  }

  postEditArticuloNoImage(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postEditArticuloNoImage', body, { headers: headers });
  }

  postEditArticuloWImage(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    //headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postEditArticuloWImage', body);
  }

  postUpdateDest(body:any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    //headers = headers.append('Authorization', `Bearer ${token}`);
    
    return this.http.post(this.url+'lt-blog/postUpdateDest', body, { headers: headers });
  }
////////////////////////////////////////END: EDITAR ARTICULO//////////////////////////////////////////////
}