import { Component, OnInit } from '@angular/core';
import { dataUserLicense } from 'src/app/models/findUser';
import { findUser } from 'src/app/services/findUser.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licencias-lt',
  templateUrl: './licencias-lt.component.html',
  styleUrls: ['./licencias-lt.component.css']
})
export class LicenciasLtComponent implements OnInit {
  dataU = new dataUserLicense();
  roles: any[] = [];
  list: any[] = [];
  constructor(private findService: findUser) {

  }

  ngOnInit() {
    this.findService.getSources().subscribe(
      response => {
        this.roles = response['roles'];
        console.log(response['roles']);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

  onSubmit() {
    const token = localStorage.getItem('token');
    console.log(this.dataU);
    this.findService.buscarUsuariosLicencia(token, this.dataU).subscribe(
      response => {
        this.list = response['lista'];
        console.log(response);
        console.log(this.list);
        console.log(this.list[0].user_license_id);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

}
