import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from './config';

import { ContactForm } from '../models/form-user';


@Injectable({
  providedIn: 'root'
})
export class ListaUsersService {
  public url = config.url;

  constructor(public http: HttpClient) { }

  // Muestra los usuarios que se encuentran en la base de datos
  mostrarUsuarios(token: string) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.get(this.url + 'admin/users', { headers: headers });
  }
  // Obtiene la informacion detallada de un usuario en particular
  verDetalleUsers(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `admin/users/${id}`;
    return this.http.get(url, { headers: headers });
  }
  // Funcion que elimina un usuario 
  // pasando como parametro el id de tipo number
  eliminarUser(token: string, id: number) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);

    // Peticion
    let url = this.url + `admin/users/${id}`;
    return this.http.delete(url, { headers: headers });
  }
  // Funcion que elimina un usuario 
  // pasando como parametro el id de tipo number
  updateUser(token: string, data: ContactForm, id) {
    // Cabezeras
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    let url = this.url + `admin/users/${id}`;
    return this.http.post(url, data, { headers: headers });
  }
  // Funcion que agrega un nuevo maestro 
  // Pasando como parametros los datos del formulario(data)
  // Pasando como parametro un id

  addTeacher(token: string, data: ContactForm) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    // Petición
    return this.http.post(this.url + `admin/users`, data, { headers: headers });
  }
}
