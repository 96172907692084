import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GameService } from '../../services/games.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router, ActivatedRoute } from '@angular/router';

// Models
import { GameDescription } from '../../models/game';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-game-description',
  templateUrl: './game-description.component.html'
})
export class GameDescriptionComponent implements OnInit {
  @Input() gameId: number;
  @Input() update: number;
  public tags: string[] = [];
  public tagsNew: string[] = [];
  public tagsDB: any[] = [];
  public gameDesc =  new GameDescription();
  private token = localStorage.getItem('token');
  public Editor = ClassicEditor;
  @Output() descCompleta: EventEmitter<number>;
  constructor(private gameService: GameService,
              private tagsService: TagsService,
              private activatedRoute: ActivatedRoute) {
    this.descCompleta = new EventEmitter();
    this.activatedRoute.params.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this.gameService.verDetalle(this.token, params.slug).subscribe((response: any) => {
          console.log(response.game)
          this.gameDesc.game_id = response.game.id;
          this.gameId = response.game.id;
          this.gameDesc.descripcion_pedagogica = response.game.descripcion_pedagogica;
          this.gameDesc.asignatura = response.game.asignatura;
          this.gameDesc.nivel = response.game.nivel;
          this.gameDesc.conceptos = response.game.conceptos;
          this.gameDesc.descripcion_ludica = response.game.descripcion_ludica;
          this.gameDesc.tags = response.game.tags;
          const regex = /\s*,\s*/;
          this.tags = this.gameDesc.tags.split(regex);
          this.tags = this.tags.filter((element) => {
            return element !== '';
          });
        }, error => {
          console.log(error);
        });
      }
    });
    this.tagsService.getTags().subscribe(
      response => {
        this.tagsDB = Object.values(response);
        let auxTag = [];
        for (const tag of this.tagsDB) {
          auxTag.push(tag.nombre);
        }
        this.tagsDB = auxTag;
      }, error => {
        console.log(error.message);
      }
    );
  }

  ngOnInit() {
  }

  addDescripcion(form: NgForm) {
    console.log(this.gameDesc);
    if (form.valid) {
      let gameData = new GameDescription();
      console.log(this.gameId);
      if ( this.gameId !== 0 || this.gameId !== undefined) {
        gameData.game_id = this.gameId;
        if (this.update === 1) {
          gameData.peticion = '1';
        }
        gameData.descripcion_pedagogica = form.value.descripcion_pedagogica;
        gameData.descripcion_ludica = form.value.descripcion_ludica;
        gameData.asignatura = form.value.asignatura;
        gameData.nivel = form.value.nivel;
        gameData.conceptos = form.value.conceptos;
        gameData.tags = form.value.tags;    
        const token = localStorage.getItem('token');
        this.gameService.crearDescripcion(token, gameData).subscribe(
          response => {
            Swal.fire({
              type: 'success',
              title: 'Se creo la descripcion',
              showConfirmButton: false,
              timer: 1500
            });
            this.descCompleta.emit(1);
          }, error => {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Ocurrio un error al crear la descripcion:' + error.error.message
            });
          }
        );
      } else {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Escoge un juego para crear la ficha'
          });
      }
    } else {
      console.log(form);
    }
  }

  changeTags(event) {
    let tags = event.target.value;
    const arrTags = tags.split(',');
    if (tags[tags.length - 1] === ',') {
      const elemento = arrTags[arrTags.length - 2].trim();
      const tbd = this.tagsDB.indexOf(elemento);
      if (this.tags.indexOf(elemento) === -1 && this.tagsNew.indexOf(elemento) === -1) {
        if (tbd === -1) {
          this.tagsNew.push(arrTags[arrTags.length - 2].trim());
        } else {
          if (this.tags.indexOf(elemento.trim()) === -1) {
            this.tags.push(elemento);
          }
        }
        const caracter = this.gameDesc.tags[this.gameDesc.tags.length - 1];
        console.log(caracter);
        this.gameDesc.tags = this.gameDesc.tags + `, ${arrTags[arrTags.length - 2].trim()}`;
        event.target.value = '';
      }
    }
  }

  eliminarTag(idx: number) {
    const len = this.tags.length;
    if (delete this.tags[idx]) {
      const arrayFiltered = this.tags.filter((element) => {
        return element != null;
      });
      this.tags = arrayFiltered;
      this.gameDesc.tags = '';
      if (this.tags.length > 0) {
        this.gameDesc.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tagsNew.length > 0) {
          this.gameDesc.tags = `${this.tagsNew.join(',')}`;
        } else {
          this.gameDesc.tags = '';
        }
      }
    }
  }

  eliminarNewTag(idx: number) {
    const len = this.tagsNew.length;
    if (delete this.tagsNew[idx]) {
      const arrayFiltered = this.tagsNew.filter((element) => {
        return element != null;
      });
      this.tagsNew = arrayFiltered;
      this.gameDesc.tags = '';
      if (this.tagsNew.length > 0) {
        this.gameDesc.tags = `${this.tags.join(',')}, ${this.tagsNew.join(',')}`;
      } else {
        if (this.tags.length > 0) {
          this.gameDesc.tags = `${this.tags.join(',')}`;
        } else {
          this.gameDesc.tags = '';
        }
      }
    }
  }

}
