import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GameDescription, GamePrograma, GameRecurso, GameAbility, GameRuta } from '../models/game';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { config } from './config';


@Injectable({
    providedIn: 'root'
})
export class GameService {
    public url = config.url;

    constructor(public http: HttpClient) { }

    /**
     * Ver todos los juegos de takthesaurus
     */
    verJuegos(token: string) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Petición
        return this.http.get(this.url + 'ts/game/', { headers: headers });
    }
    verDetalle(token: string, slug: string) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        // Peticion
        return this.http.get(this.url + `ts/game/${slug}`, { headers: headers })
            .pipe( map( data => {
                    return data[ 'ficha' ];
                })
            );
    }
    eliminarFicha(token: string, slug: string) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        // Peticion
        return this.http.delete(this.url + `ts/game/${slug}`, { headers: headers });
    }
    crearDescripcion(token: string, game: GameDescription) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);
        // Peticion
        return this.http.post(this.url + `ts/game`, game, { headers: headers });
    }
    addProgramas(token: string, programa: GamePrograma) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.http.post(this.url + `ts/programas`, programa, {headers: headers});
    }
    addRecursos(token: string, recursos: GameRecurso) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.http.post(this.url + `ts/otros_recursos`, recursos, { headers: headers });
    }
    inomaGames() {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(this.url + `inoma/games`, { headers: headers });
    }
    addAbility(token: string, habilidad: GameAbility ) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.http.post(this.url + `ts/abilities`, habilidad, {headers: headers});
    }
    addRuta(token: string, habilidad: GameRuta ) {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.http.post(this.url + `ts/rutas`, habilidad, {headers: headers});
    }
    eliminarRuta(token: string,idruta,idgame) {
        // Cabezeras
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${token}`);

        return this.http.delete(this.url + `ts/rutas/${idruta}/${idgame}`,  { headers: headers });

        // Peticion
        // let url = this.url + `ts/rutas/${id}`;
        // return this.http.delete(url, gameId, { headers: headers });
    }

}
