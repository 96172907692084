import { Component, OnInit } from '@angular/core';
import { usersAU } from 'src/app/models/findUser';
import { findUser } from 'src/app/services/findUser.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-au',
  templateUrl: './users-au.component.html',
  styleUrls: ['./users-au.component.css']
})
export class UsersAuComponent implements OnInit {
  dataU = new usersAU();
  roles: any[] = [];
  list: any[] = [];
  constructor(private findService: findUser) {

  }

  ngOnInit() {
    this.findService.getSources().subscribe(
      response => {
        this.roles = response['roles'];
        console.log(response['roles'].id);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

  onSubmit() {
    const token = localStorage.getItem('token');
    console.log(this.dataU);
    this.findService.buscarUsuariosAU(token, this.dataU).subscribe(
      response => {
        this.list = response['lista'];
        console.log(this.list);
        console.log(this.list[0].user_rol);
      }, error => {
        console.log(error);
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al buscar los registros:' + error.error.message
        });
      }
    );
  }

}
