export class Mooc {
  public titulo: string = '';
  public id_tema: string = null;
  public tags = [0];
  public fecha_inicio: string = '';
  public duracion: number = null;
  public descripcion: string = '';
  public nivel: string = '';
  public idioma: string = '';
  public autores: string = '';
  public certificado: number = 0;
  public especial: number = 0;
  public cobrar: number = 0;
  public precio: number = 0;
  public status: number = 1;
  public image;
  public modulos: Array<Modulo>;
}

export class Modulo {
  public id: number;
  public id_mooc: number = null;
  public modulo: number = null;
  public nombre	: string  = '';
  public descripcion: string = '';
  public duracion: number = null;
  public tipo_evaluacion: string = '';
  public actividades: Array<Actividad>;
  public deleted: boolean;
}

export class Actividad {
  public id: number;
  public id_modulo: number;
  public modulo: number;
  public actividad: number;
  public nombre: string = '';
  public descripcion: string = '';
  public materiales: Array<Materiales>;
}

export class Materiales { 
  public id: number;
  public id_actividad: number;
  public actividad: number;
  public orden: number;
  public nombre: string = '';
  public tipo: string = '';
  public duracion: number;
  public status: number = 1;
  public file: File;
  public change: boolean;
  public archivo: String;
}