import { Component, OnInit, Renderer2} from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { imagenesBlog, config } from '../../services/config';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-blogEditarArticulo',
    templateUrl: './blogEditarArticulo.component.html',
    styleUrls: ['./blogEditarArticulo.component.css']
})

export class blogEditarArticuloComponent implements OnInit {
    
    private urlIMG = imagenesBlog.url;
    public url = config.url;

    private editAviable:boolean;
    private editImageAvailable:boolean;
    
    private articulosList:any;
    private categoriesList:any;
    private tagsList:any;
    private autorsList:any;

    private articuloSelec:any;
    private comentarios:any;

    //articulo
    private ID: string;
    private visible:boolean;
    private destacado:boolean;
    private autor:number;
    private categoria:number;
    private tags:string;
    private titulo:string;
    private resumen:string;
    private imagen:string;
    private articulo:string;

    private tagsArray: any =[];
    private imagenEdit: Array <File>;
    private flag:boolean;

    constructor(private blogService: BlogService, private renderer: Renderer2){
    } 

    ChangeStatusDest(e:any, ID:number){
        let status = Number(e.checked).toString();
        let id = ID.toString();
        this.blogService.postUpdateDest({"destacado":status,"ID":id}).subscribe((response: any) => {
            Swal.fire({
                position: 'top-end',
                title: 'Articulo Destacado Actualizado',
                showConfirmButton: false,
                timer: 1500,
                type: 'success'
            });
        });
    }

    uploadArticleNoImage(){
        let tagsString = this.tagsArray.toString();
        if(
            this.autor != undefined &&
            this.categoria != undefined &&
            !(this.articulo == undefined || this.articulo == "") &&
            !(this.titulo == undefined || this.titulo == "") &&
            !(this.resumen == undefined || this.resumen == "") &&
            tagsString != ""
        ){
            let fd = {
                'ID': this.ID,
                'visible': Number(this.visible).toString(),
                'destacado': Number(this.destacado).toString(),
                'IDautor': this.autor.toString(),
                'IDcategoria': this.categoria.toString(),
                'articulo': this.articulo,
                'titulo': this.titulo,
                'resumen': this.resumen,
                'tags': tagsString
            }
            this.blogService.postEditArticuloNoImage(fd).subscribe((response: any) => {
                this.editAviable = false;
                Swal.fire({
                    position: 'top-end',
                    title: 'Articulo Actualizado',
                    showConfirmButton: false,
                    timer: 1500,
                    type: 'success'
                });
                this.refreshArticulosList();
            });
        }
        else{
            Swal.fire("","No puede dejar campos en blanco","warning");
        }
    }

    uploadArticleWImage(){
        let tagsString = this.tagsArray.toString();
        if(this.imagenEdit != undefined)
        {
            if(this.imagenEdit[0].type == "image/jpeg" || this.imagenEdit[0].type == "image/png"){
                this.flag = true;
                // if(this.imagenEdit[0].size > 51000 ){
                //     this.flag = false;
                //     Swal.fire("","La imagen debe pesar menos de 50KB","warning");
                // }
                // else{
                //     this.flag = true;
                // }
            }
            else{
                this.flag = false;
                Swal.fire("","Debe seleccionar una imagen jpg o png","warning");                
            }
        }
        else{
            this.flag = false;
            Swal.fire("","Debe seleccionar una imagen principal","warning");
        }
        if(
            this.autor != undefined &&
            this.categoria != undefined &&
            !(this.articulo == undefined || this.articulo == "") &&
            !(this.titulo == undefined || this.titulo == "") &&
            !(this.resumen == undefined || this.resumen == "") &&
            tagsString != "" && this.flag && this.imagenEdit !=undefined
        ){
            let fd = new FormData();
            fd.append('image',this.imagenEdit[0],this.imagenEdit[0].name);
            fd.append('ID', this.ID);
            fd.append('visible', Number(this.visible).toString());
            fd.append('destacado', Number(this.destacado).toString());
            fd.append('IDautor', this.autor.toString());
            fd.append('IDcategoria', this.categoria.toString());
            fd.append('articulo', this.articulo);
            fd.append('titulo', this.titulo);
            fd.append('resumen', this.resumen);
            fd.append('tags', tagsString);
            this.blogService.postEditArticuloWImage(fd).subscribe((response: any) => {
                this.editAviable = false;
                Swal.fire({
                    position: 'top-end',
                    title: 'Articulo Actualizado',
                    showConfirmButton: false,
                    timer: 1500,
                    type: 'success'
                });
                this.refreshArticulosList();
            });
        }
        else{
            if(this.flag) Swal.fire("","Favor de llenar todos los campos","warning");
        }
    }

    selectImage(e:any){
        this.imagenEdit = e.target.files;
    }

    SelectTags(e:any, tag:any){
        let clase = e.target.className;
        if(clase == "unselected"){
            this.renderer.removeClass(e.target, 'unselected');
            this.renderer.addClass(e.target, 'selected');
            if(!this.tagsArray.includes(tag))
                this.tagsArray = this.tagsArray.concat([tag]);
        }
        if(clase == "selected"){
            this.renderer.removeClass(e.target, 'selected');
            this.renderer.addClass(e.target, 'unselected');
            if(this.tagsArray.includes(tag))
                var rm = this.tagsArray.splice(this.tagsArray.indexOf(tag), 1);
        }
    }

    async selectArticle(id:number){
        this.blogService.getArticuloSelec(id.toString()).subscribe((response: any) => {
            this.editAviable = true; 
            this.editImageAvailable = false;
            this.articuloSelec = response.article;
            this.comentarios = response.comments;

            console.log(this.articuloSelec, this.comentarios);
            
            this.ID = this.articuloSelec.ID.toString();
            console.log({"ID":this.ID});
            this.visible = this.articuloSelec.visible;
            this.destacado = this.articuloSelec.destacado;
            this.autor = this.articuloSelec.IDautor;
            this.categoria = this.articuloSelec.IDcategoria;
            this.tags = this.articuloSelec.tags;
            this.titulo = this.articuloSelec.titulo;
            this.resumen = this.articuloSelec.resumen;
            this.imagen = this.articuloSelec.imagen;
            this.articulo = this.articuloSelec.articulo;

            this.tagsArray = this.tags.split(",");
            setTimeout(() => this.refreshAndSelectTags() , 300);
        }); 
    }

    refreshAndSelectTags(){
        for(let i=0; i<this.tagsList.length; i++){
            var element = document.getElementById(this.tagsList[i].tag);
            element.classList.remove("selected");
            element.classList.add("unselected");
        }
        for(let i=0; i<this.tagsList.length; i++){
            if(this.tagsArray.includes(this.tagsList[i].tag)){
                var element = document.getElementById(this.tagsList[i].tag);
                element.classList.remove("unselected");
                element.classList.add("selected");
            }
        }
    }

    ChangeStatusComment(e:any, ID:number){
        let status = Number(e.checked).toString();
        let id = ID.toString();
        this.blogService.postUpdateComment({"visible":status,"ID":id}).subscribe((response: any) => {
            Swal.fire({
                position: 'top-end',
                title: 'Comentario Actualizado',
                showConfirmButton: false,
                timer: 1500,
                type: 'success'
            });
        });
    }

    refreshArticulosList(){
        this.blogService.getArticulos().subscribe((response: any) => {
            this.articulosList = response;
            console.log(this.articulosList);
        });
    }

    ngOnInit() {
        this.refreshArticulosList();
        this.blogService.getTags().subscribe((response: any) => {
            this.tagsList = response;
            console.log(this.tagsList);
        });
        this.blogService.getCategories().subscribe((response: any) => {
            this.categoriesList = response;
        });
        this.blogService.getAutores().subscribe((response: any) => {
            this.autorsList = response;
        }); 
        this.editAviable = false;
        this.editImageAvailable = false;
        this.flag = false;
    }

    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: '25rem',
          minHeight: '5rem',
          maxHeight: 'auto',
          width: '650px',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Escriba el articulo aqui...',
          defaultParagraphSeparator: '',
          defaultFontName: 'Calibri',
          defaultFontSize: '',
          fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
          ],
        uploadUrl: this.url + 'lt-blog/imageblog',
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [ ['fontSize'] ]
    };
}